import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  fetchWBLocalVideos,
  uploadWBLocalVideos,
} from "../../../../../../excalidraw-app/api/collection";
import { CardWithImage } from "../../../../components/card";
import Loader from "../../../../../../App/shareComponent/Loader";
import { Dialog } from "../../../../../Dialog";
import { ToolButton } from "../../../../../ToolButton";
import { loadVideo } from "../../../../../icons";
import { t } from "../../../../../../i18n";
import "./style.scss";
import { MediaCollectionProps } from "../imageCollection";
import { MediaHandlersRef } from "../../CollectionsTab";
import { deleteWbLocalMedia } from "../../../../../../excalidraw-app/api/userAPI";
import { Toast, ToastType } from "../../../../../Toast";
import { Subscription } from "../../../../../../types";
import { NonDeletedExcalidrawElement } from "../../../../../../element/types";

interface videoType {
  createdAt: string;
  url: string;
  name: string;
  fileSize: string;
  isSelected: boolean;
  isLoading: boolean;
}

export const VideoCollection = forwardRef<
  MediaHandlersRef,
  MediaCollectionProps & {
    subscription: Subscription | undefined;
    elements: NonDeletedExcalidrawElement[];
  }
>(
  (
    {
      insertOnCanvas,
      setAppState,
      setDeleteElement,
      deleteElement,
      subscription,
      elements,
      searchVal,
    },
    ref,
  ) => {
    const recordLimit = (subscription && subscription.videoRecordLimit) || 40;
    const [videos, setVideos] = useState<videoType[]>([]);
    const [filteredVideos, setFilteredVideos] = useState<videoType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [previewVideoDialog, setPreviewVideoDialog] = useState(false);
    const [previewVideo, setPreviewVideo] = useState("");
    const [record, setRecord] = useState<any>({});
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [currentBlobName, setCurrentBlobName] = useState<string>("");
    const [isExceedVideoLimit, setIsExceedVideoLimit] = useState<boolean>(
      false,
    );
    const [alreadyExistVideo, setAlreadyExistVideo] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      onDelete: handleDelete,
      onUpload: onVideoChange,
    }));

    const fetchVideoCollection = async () => {
      try {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem("user") || "");

        const { results } = await fetchWBLocalVideos(user.mail);
        setRecord(results.length ? results[0] : {});

        const allLocalVideos = results.reduce(
          (acc: string | any[], curr: { localVideos: any }) => {
            return acc.concat(curr.localVideos);
          },
          [],
        );

        const videos = allLocalVideos.length
          ? allLocalVideos.map((video: { url: string; name: string }) => {
              return {
                ...video,
                isSelected: false,
                isLoading: true,
              };
            })
          : [];

        setVideos(videos.length > 0 ? videos : []);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    useEffect(() => {
      fetchVideoCollection();
    }, []);

    useEffect(() => {
      if (searchVal) {
        const searchResult = videos.filter((video) =>
          video.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredVideos(searchResult);
      }
      setFilteredVideos(videos);
    }, [searchVal, videos]);

    const handleDelete = async () => {
      try {
        const body = {
          id: record.id,
          classId: record.classId,
          email: record.email,
          type: "video",
          blobNames: deleteElement
            ? deleteElement
            : currentBlobName
            ? [currentBlobName]
            : [],
          typeOfDelete: "single",
        };
        await deleteWbLocalMedia(body);

        let arr: videoType[] = [];
        const deleteVideoFromVideos = filteredVideos.filter((video) => {
          if (!deleteElement?.includes(video.name)) {
            arr.push({ ...video });
          }
        });
        setFilteredVideos(arr);
      } catch (error) {
        console.log("error in delete video", error);
      }
    };

    const onVideoChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const selectedFile = event.target.files?.[0];
      const orignalFileName = selectedFile?.name.replace(/\s+/g, "_");
      const isFileAlreadyExists = videos.some(
        (video) => video.name === orignalFileName,
      );

      const date = new Date();
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const fileUrl = reader.result as string;

          if (isFileAlreadyExists) {
            setToastMessage("");
            setAlreadyExistVideo(true);
            return;
          }

          const video = document.createElement("video");
          video.src = selectedFile ? URL.createObjectURL(selectedFile) : "";

          video.onloadedmetadata = async () => {
            if (video.duration > Number(recordLimit)) {
              setToastMessage(
                `Upload failed: The video file exceeds the ${recordLimit}-second limit. Please upload an video file within the allowed duration`,
              );
              return;
            } else {
              const file_ = event.target.files?.[0];
              const lesson = JSON.parse(localStorage.getItem("lesson") || "");

              // Find file sizes | Bytes, KB, MB, GB
              let bytes: any = file_ ? file_.size : 0;
              if (bytes >= 1073741824) {
                bytes = (bytes / 1073741824).toFixed(2) + " GB";
              } else if (bytes >= 1048576) {
                bytes = (bytes / 1048576).toFixed(2) + " MB";
              } else if (bytes >= 1024) {
                bytes = (bytes / 1024).toFixed(2) + " KB";
              } else if (bytes > 1) {
                bytes = bytes + " bytes";
              } else if (bytes == 1) {
                bytes = bytes + " byte";
              } else {
                bytes = "0 bytes";
              }

              const user = JSON.parse(localStorage.getItem("user") || "");
              if (file_ && lesson && user.mail) {
                const formData = new FormData();

                formData.append("file", file_);
                formData.append("classId", lesson?.ClassID as string);
                formData.append("mail", user.mail);
                formData.append("fileSize", bytes);

                setIsLoading(true);

                await uploadWBLocalVideos(formData);

                const newVideo: videoType = {
                  createdAt: date as any,
                  fileSize: bytes,
                  name: file_?.name,
                  url: fileUrl,
                  isSelected: false,
                  isLoading: true,
                };

                setFilteredVideos([...filteredVideos, newVideo]);
                setIsLoading(false);
              }
            }
          };

          video.onerror = () => {
            setIsLoading(false);
            console.error("Error loading video:", video.src);
          };
        } catch (err) {
          setIsLoading(false);
          console.error("Error processing file:", err);
        }
      };

      selectedFile && reader.readAsDataURL(selectedFile);
    };

    const insertVideo = async (file: File) => {
      if (!file) return;
      const perPageVideoLimit = subscription ? subscription.videoLimit : 0;
      const nonDeletedVideo = elements.filter(
        (data) => data.type === "video" && data.isDeleted === false,
      );
      setIsExceedVideoLimit(
        nonDeletedVideo.length < Number(perPageVideoLimit) ? false : true,
      );
      if (nonDeletedVideo.length < Number(perPageVideoLimit)) {
        insertOnCanvas(file);
        setIsLoading(false);
        setAppState({ isLibraryOpen: false });
      } else {
        setToastMessage("");
      }
    };

    const user = JSON.parse(localStorage.getItem("user") || "");

    return (
      <>
        {previewVideoDialog && (
          <Dialog
            onCloseRequest={() => setPreviewVideoDialog(false)}
            title={
              <>
                <div className="preview-dialog-div">
                  {/* <ToolButton
                    className="loadButton"
                    type="button"
                    icon={<svg className="load_icon">{loadVideo}</svg>}
                    title={t("collection.personal.audios.uploadBtn")}
                    aria-label={t("labels.alignTop")}
                  >
                    <span className="uploadButton">
                      {t("collection.personal.audios.uploadBtn")}
                    </span>
                  </ToolButton> */}
                  <span className="preview-heading">
                    {t("confirmDialog.previewCollectionVideo")}
                  </span>
                </div>
              </>
            }
            closeOnClickOutside={true}
            open={previewVideoDialog}
            setOpen={() => true}
            children={
              <video
                src={`${previewVideo}`}
                controls
                autoPlay
                className="w-100 h-100"
              />
            }
            className="preview-dialog"
          />
        )}
        <div
          className="row overflow-auto"
          style={{ height: "calc(100% - 28%)" }}
        >
          {isLoading ? (
            <Loader className="h-100" />
          ) : (
            filteredVideos.map((video, index) => {
              const nameWithoutExtension = video.name
                .split(".")
                .slice(0, -1)
                .join(".");
              return (
                <CardWithImage
                  key={index}
                  created={video.createdAt}
                  images={video.url}
                  name={nameWithoutExtension}
                  userProfile={user?.UrlPhoto}
                  userName={user?.name}
                  type="video"
                  buttonText={t("cards.importOnCanvas")}
                  likeBookmarkIcon={false}
                  isHoverPreviewBtns={true}
                  onPreview={() => {
                    setPreviewVideo(video.url);
                    setPreviewVideoDialog(true);
                  }}
                  onDelete={() => {
                    if (deleteElement && deleteElement.includes(video.name)) {
                      setDeleteElement &&
                        setDeleteElement((prev) =>
                          prev.filter((el) => el !== video.name),
                        );
                      setCurrentBlobName("");
                    } else {
                      setDeleteElement &&
                        setDeleteElement((prev) => [...prev, video.name]);
                      setCurrentBlobName(video.name);
                    }
                  }}
                  isDelete={true}
                  isHoverUseThisTemplateBtn={false}
                  onAddtoCollectionBtnClick={async () => {
                    setIsLoading(true);
                    const response = await fetch(video.url);
                    if (!response.ok) {
                      throw new Error(
                        `Failed to fetch video (status ${response.status})`,
                      );
                    }
                    const data = await response.blob();

                    const file_ = new File([data], "video.mp4", {
                      type: data.type || "video/mp4",
                    });
                    setIsLoading(false);
                    insertVideo(file_);
                  }}
                />
              );
            })
          )}
          {!isExceedVideoLimit && toastMessage !== null && (
            <Toast
              type={ToastType.ERROR}
              message={toastMessage}
              clearToast={() => setToastMessage(null)}
              className="style"
            />
          )}
          {isExceedVideoLimit && toastMessage !== null && (
            <Toast
              type={ToastType.ERROR}
              message={`You've exceeded the limit You're allowed to upload a maximum of ${
                subscription && subscription.videoLimit
              } videos per page.`}
              clearToast={() => setToastMessage(null)}
              className="style"
            />
          )}
          {alreadyExistVideo && toastMessage !== null && (
            <Toast
              type={ToastType.ERROR}
              message={`The selected file name already exists. Please choose a unique file name to proceed.`}
              clearToast={() => setToastMessage(null)}
              className="style"
            />
          )}
        </div>
      </>
    );
  },
);
