import { useEffect, useRef, useState } from "react";
import docIcon from "../../../../../../assests/doc.png";
import pdfIcon from "../../../../../../assests/pdf.png";
import pptIcon from "../../../../../../assests/pptx.png";
import { formatFileSize } from "../../../../../../utils/fileSize";
import { Dialog } from "../../../../../Dialog";
import { extractTextFromDOC, getDOCPageCount } from "./media/doc";
import { extractTextFromPDF, getPDFPageCount } from "./media/pdf";
import { extractTextFromPPT, getPPTSlideCount } from "./media/ppt";
import "./styles.scss";
import { getSettingData } from "../../../../../../excalidraw-app/api/userAPI";
import { ToastType, Toast } from "../../../../../Toast";

interface AttachementModalProps {
  setOpen: (open: boolean) => void;
  open: boolean;
  selectedFiles: File[];
  extractedText: string;
  onAttachFiles: (files: File[], extractedText: string) => void;
}

export const AttachementModal = (props: AttachementModalProps) => {
  const {
    setOpen,
    selectedFiles: exsitingFiles,
    open,
    onAttachFiles,
    extractedText: existingExtractedText,
  } = props;

  // const [extractedText, setExtractedText] = useState<string>("");

  const [selectedFiles, setSelectedFiles] = useState<File[]>(exsitingFiles);

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // setSelectedFiles((prevFiles) => [ , ...Array.from(files)]);
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  // Remove file from the list
  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const extractedText = useRef<string>(existingExtractedText);

  const [renderContent, setRenderContent] = useState<JSX.Element[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const resetAndRenderFiles = async () => {
      setRenderContent([]);

      const updatedContent = await Promise.all(
        selectedFiles.map((file, index) => renderFilePreview(file, index)),
      );

      setRenderContent(updatedContent.filter(Boolean) as JSX.Element[]);
    };

    resetAndRenderFiles();
  }, [selectedFiles]);
  let limitSize = 10;
  const checkImportDocumentExceedLimit = async (file: File) => {
    //fir get setting data for pdf size
    const slug = new URLSearchParams(window.location.search).get("slug");

    const validSlug = slug ?? "";
    const res = await getSettingData(validSlug);
    limitSize = res.data[0]?.importedDocumentSizeInMB;
    if (limitSize) {
      const isExceededLimit = file.size > 1024 * 1024 * limitSize;

      return isExceededLimit;
    }
  };

  // Helper function to generate file previews or names
  const renderFilePreview = async (file: File, index: number) => {
    const fileSize = formatFileSize(file.size);
    const checkFileExceedLimit = await checkImportDocumentExceedLimit(file);
    if (checkFileExceedLimit) {
      return setErrorMessage(
        `${file.name} exceeds the size limit of ${limitSize} MB`,
      );
    }
    const fileExtension = file.name.split(".").pop()?.toUpperCase();
    let newText = "";

    // Extract text based on file type
    if (file.type.startsWith("image/")) {
      extractedText.current = "";
    } else {
      newText = await extractFileText(file, fileExtension);
      extractedText.current = `${extractedText.current} ${newText}`;
    }

    const data = await fetchFileDetails(file, fileSize, fileExtension);
    return renderFileCard(file, index, fileExtension, data);
  };

  // Helper function to extract text based on file extension
  const extractFileText = async (file: File, fileExtension?: string) => {
    switch (fileExtension) {
      case "PDF":
        return await extractTextFromPDF(file);
      case "DOC":
      case "DOCX":
        return await extractTextFromDOC(file);
      case "PPT":
      case "PPTX":
        return await extractTextFromPPT(file);
      default:
        return "";
    }
  };

  // Helper function to fetch file details (size, pages)
  const fetchFileDetails = async (
    file: File,
    fileSize: string,
    fileExtension?: string,
  ) => {
    let pages = 0;

    switch (fileExtension) {
      case "PDF":
        pages = await getPDFPageCount(file);
        break;
      case "DOC":
      case "DOCX":
        pages = await getDOCPageCount(file);
        break;
      case "PPT":
      case "PPTX":
        pages = await getPPTSlideCount(file); // For PPT/PPTX, count the slides
        break;
      default:
        break;
    }

    return { size: fileSize, pages };
  };

  // Helper function to render the file card
  const renderFileCard = (
    file: File,
    index: number,
    fileExtension?: string,
    data?: { size: string; pages: number },
  ) => {
    const isPDF = fileExtension === "PDF";
    const isDOC = fileExtension === "DOC" || fileExtension === "DOCX";
    const isPPT = fileExtension === "PPT" || fileExtension === "PPTX";

    // Render image preview for image files
    if (file.type.startsWith("image/")) {
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data?.size} {" • "} {fileExtension}
              </div>
            </div>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              className="preview-image"
            />
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    }

    // Render file card for PDF, DOC, and PPT files
    if (isPDF || isDOC || isPPT) {
      return (
        <div key={index} className="file-preview m-2">
          <div className="d-flex justify-content-between w-100 p-2">
            <div className="file-info d-flex flex-column justify-content-start">
              <div className="file-name">{file.name.trim()}</div>
              <div className="file-type">
                {data?.size}, {data?.pages} Pages {" • "} {fileExtension}
              </div>
            </div>
            <div className="file-icon">
              <img
                src={isPDF ? pdfIcon : isDOC ? docIcon : pptIcon}
                alt={`${fileExtension} Icon`}
                className="file-icon-img"
              />
            </div>
          </div>
          <button
            className="remove-btn"
            onClick={() => handleRemoveFile(index)}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <Dialog
        isShowFullScreenButton={true}
        children={
          <>
            {errorMessage && (
              <Toast
                type={ToastType.ERROR}
                message={errorMessage}
                clearToast={() => {
                  setErrorMessage(""); // Clears the error message state
                }}
                style={{
                  left: "50% !important",
                }}
              />
            )}
            <div className="attachment-modal d-flex flex-column p-3">
              <div className="d-flex flex-column flex-grow-1 gap-3">
                <p className="text-secondary attachment-note">
                  Files and Library will be added to your ACV blob storage
                </p>
                <div>
                  <label
                    htmlFor="file-upload"
                    className="file-btn btn btn-outline"
                  >
                    Add File
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                    // accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple // Allow multiple files
                  />
                </div>
                <div className="attachment-body my-2">
                  {selectedFiles.length > 0 ? (
                    <div className="file-preview-container ">
                      {renderContent}
                    </div>
                  ) : (
                    <p className="m-auto d-flex text-center ">
                      No attached files!!
                    </p>
                  )}
                </div>
                {/* comment below section for RTM */}
                {/* <div>
                  <label
                    htmlFor="library-upload"
                    className="file-btn btn btn-outline"
                  >
                    Add Library
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="acv-blob"
                    className="file-btn btn btn-outline"
                  >
                    Select from my ACV Blob
                  </label>
                </div>
                <div>
                  <label htmlFor="add-url" className="file-btn btn btn-outline">
                    Add URL
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="open-source"
                    className="file-btn btn btn-outline"
                  >
                    Add Azure open AI Source..
                  </label>
                </div> */}
              </div>

              <div className="attachment-footer d-flex justify-content-between mt-3 flex-wrap">
                <div className="d-flex flex-column">
                  {/* <div className="footer-note">
                    <span
                      style={{
                        color: "#494b83",
                      }}
                    >
                      127.5 MB{" "}
                    </span>{" "}
                    of 500 MB used
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: "45%",
                      }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <div className="footer-note text-secondary">
                    storage usage
                  </div> */}
                </div>

                <div className="d-flex gap-3 flex-wrap">
                  <button className="cancel-btn" onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                  <button
                    className="update-btn"
                    disabled={!exsitingFiles.length && !selectedFiles.length}
                    onClick={() => {
                      onAttachFiles(selectedFiles, extractedText.current);
                      setOpen(false);
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </>
        }
        onCloseRequest={() => setOpen(false)}
        closeOnClickOutside={false}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
