import { KEYS } from "../keys";
import { register } from "./register";
import { selectGroupsForSelectedElements } from "../groups";
import { getNonDeletedElements, isTextElement } from "../element";
import { excludeElementsInFramesFromSelection } from "../scene/selection";
import { ExcalidrawElement } from "../element/types";
import { isLinearElement } from "../element/typeChecks";
import { LinearElementEditor } from "../element/linearElementEditor";

export const actionSelectAll = register({
  name: "selectAll",
  perform: (elements, appState, value, app) => {
    if (appState.editingLinearElement) {
      return false;
    }

    const selectedElementIds = excludeElementsInFramesFromSelection(
      elements.filter(
        (element) =>
          !element.isDeleted &&
          !(isTextElement(element) && element.containerId) &&
          element.locked !== true,
      ),
    ).reduce((map: Record<ExcalidrawElement["id"], true>, element) => {
      map[element.id] = true;
      return map;
    }, {});

    return {
      // appState: selectGroupsForSelectedElements(
      //   {
      //     ...appState,
      //     editingGroupId: null,
      //     selectedElementIds: elements.reduce((map, element) => {
      //       if (!element.isDeleted && !element.locked) {
      //         map[element.id] = true;
      //       }
      //       return map;
      //     }, {} as any),
      //   },
      //   getNonDeletedElements(elements),
      //   appState,
      //   app,
      // ),
      appState: {
        ...appState,
        ...selectGroupsForSelectedElements(
          {
            editingGroupId: null,
            selectedElementIds,
          },
          getNonDeletedElements(elements),
          appState,
          app,
        ),
        selectedLinearElement:
          // single linear element selected
          Object.keys(selectedElementIds).length === 1 &&
          isLinearElement(elements[0])
            ? new LinearElementEditor(elements[0], app.scene)
            : null,
      },
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.selectAll",
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.A,
});
