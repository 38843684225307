import React, { useEffect, useState, useMemo, RefObject } from "react";
import { Dialog } from "./Dialog";
import throttle from "lodash.throttle";

import { backIcon } from "./icons";

import "./UnderReview.scss";

import "./LibraryDialog.scss";
import "./Sidebar.scss";

import LibrarySidebar from "./LibrarySidebar";
import TemplateContent from "./TemplateContent";
import TemplateInfo from "./TemplateInfo";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { API_URL, sidebarText } from "../excalidraw-app/api/constant";
import StockImages from "./StockImages";
import MyCollection from "./MyCollection";
import Images from "./Images";
import Videos from "./Videos";
import Audios from "./Audios";
import UnderReview from "./UnderReview";
import Like from "./Like";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  Filter,
  LibraryFileType,
  LibraryItem,
  LibraryItems,
  StockImagesType,
  Subscription,
} from "../types";
import {
  bookmarkCollectionAPI,
  fetchAllCollection,
  fetchFilteredCollectionData,
  fetchMyPublishedCollectionAPI,
  fetchStockImagesAPI,
  fetchTemplatesFilterOptions,
  fetchUnderReviewCollectionsAPI,
  likeUnlikeCollectionAPI,
  withdrawCollectionApprovalRequestAPI,
} from "../excalidraw-app/api/collection";
import { LOAD_STOCK_IMAGE_TIMEOUT } from "../excalidraw-app/app_constants";
import { getUserInfo } from "../excalidraw-app/api/getuserInfo";
import Bookmark from "./Bookmark";
import { t } from "../i18n";
import EditLibrary from "./EditLibrary";
import { useIsMobile } from "./App";
import { urlToDataUrl } from "../utils/convertToDataURL";
import { MIME_TYPES } from "../constants";
import { NonDeletedExcalidrawElement } from "../element/types";
import axios from "axios";
import { apiPost } from "../excalidraw-app/api";

export type UnderReviewInput = {
  userId: string;
};

export type WithdrawRequestInput = {
  id: string;
};

export type StockImagesInput = {
  query: string;
};

export type filteredValueType = {
  age: string;
  author: string[];
  tags: string[];
  search?: string;
};

export const LibraryDialog = ({
  onClose,
  insertImageOnCanvas,
  onInsertShape,
  insertVideoOnCanvas,
  insertAudioOnCanvas,
  setAppState,
  appState,
  setLibraryItems,
  libraryItems,
  editCollection,
  elements,
  subscription,
  importLibraryFromUrl,
}: {
  onClose?: () => void;
  insertImageOnCanvas: (file: File) => void;
  insertVideoOnCanvas: (file: File) => void;
  insertAudioOnCanvas: (file: File) => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  setLibraryItems: (val: LibraryItems) => void;
  libraryItems: LibraryItems;
  editCollection: (libraryItems: LibraryItems) => void;
  elements: readonly NonDeletedExcalidrawElement[];
  subscription?: Subscription;
  importLibraryFromUrl: (urls: string[]) => void;
}) => {
  const lesson = JSON.parse(localStorage.getItem("lesson") || "");
  const parts = lesson ? lesson?.ClassName?.split("-") : ["Nature"];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>(sidebarText().TEMPLATE);
  const [collections, setCollections] = useState<CollocationType[]>([]);
  const [templateInfo, setTemplateInfo] = useState<CollocationType>();
  const [underReviewData, setUnderReviewData] = useState<CollocationType[]>([]);
  const [images, setImages] = useState<StockImagesType[]>([]);
  const [searchVal, setSearchVal] = useState<string>(
    lesson ? parts[0] : "Nature",
  );
  const isMobile = useIsMobile();
  const [myCollections, setMycollections] = useState<CollocationType[]>([]);
  const [likeData, setLikeData] = useState<CollocationType[]>([]);
  const [bookmarkData, setBookmarkData] = useState<CollocationType[]>([]);
  const [editLibData, setEditLibData] = useState<CollocationType>();
  const [filterOption, setFilterOption] = useState<Filter>({
    authors: [],
    tags: [],
  });
  const [filterData, setFilterData] = useState<string[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [
    filterDataWithKeys,
    setFilterDataWithKeys,
  ] = useState<filteredValueType>({
    age: "",
    author: [],
    tags: [],
  });

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleClick = (val: string) => {
    setContent(val);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const user = await getUserInfo();
      const { result } = await fetchAllCollection({});
      const { result: filter } = await fetchTemplatesFilterOptions();
      setFilterOption(filter);

      fetchUnderReviewCollections();
      const likeData = result.resources.filter(
        (item: CollocationType) => item.isLiked,
      );
      const bookmarkData = result.resources.filter(
        (item: CollocationType) => item.isBookmark,
      );
      setCollections(result.resources);
      setLikeData(likeData);
      setBookmarkData(bookmarkData);
      fetchStockImages();
      // insertRow();
      fetchMyPublishedCollections();
      setIsLoading(false);
    })();
  }, []);

  // const insertRow = () => {
  //   apiPost(`${API_URL}/insert-feild`);
  // };

  useEffect(() => {
    const data = [...collections];
    const likeData = data.filter((item: CollocationType) => item.isLiked);
    const bookmarkData = data.filter(
      (item: CollocationType) => item.isBookmark,
    );
    if (content === "Under review") {
      fetchUnderReviewCollections();
    }
    setLikeData(likeData);
    setBookmarkData(bookmarkData);
  }, [content]);

  const fetchUnderReviewCollections = async () => {
    try {
      setIsLoading(true);

      const user = await getUserInfo();

      const apiBody: UnderReviewInput = {
        userId: user.mail,
      };
      const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
      setUnderReviewData(result);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchFilteredData = async (filterDataWithKeys: filteredValueType) => {
    try {
      setIsLoading(true);
      const { result } = await fetchFilteredCollectionData(filterDataWithKeys);

      fetchUnderReviewCollections();
      const likeData = result.resources.filter(
        (item: CollocationType) => item.isLiked,
      );
      const bookmarkData = result.resources.filter(
        (item: CollocationType) => item.isBookmark,
      );
      setCollections(result.resources);
      setLikeData(likeData);
      setBookmarkData(bookmarkData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error-in-fetchFilteredData", error);
    }
  };

  // @used for withdraw collection request
  const withdrawCollectionApprovalRequest = async (id: string) => {
    try {
      const apiBody: WithdrawRequestInput = {
        id,
      };
      await withdrawCollectionApprovalRequestAPI(apiBody);
      fetchUnderReviewCollections();
    } catch (err) {
      console.log(err);
    }
  };

  const changeHandler = (data: string) => {
    setSearchVal(data);
    debouncedChangeHandler(data);
  };

  const storeFilesInAppState_ = (files: LibraryFileType[]) => {
    let convertedFiles: BinaryFiles = {};
    for (let index = 0; index < files.length; index++) {
      const file: LibraryFileType = files[index];
      !appState.files.hasOwnProperty(file.id) &&
        urlToDataUrl(file.url, file.mimeType).then(async (result) => {
          if (result) {
            convertedFiles[file.id as string] = {
              mimeType:
                file.mimeType ||
                (MIME_TYPES.binary as BinaryFileData["mimeType"]),
              id: file.id as BinaryFileData["id"],
              dataURL: result.dataUrl as BinaryFileData["dataURL"],
              created: file.created,
              isPublished: true,
            };
          } else {
            console.log("Failed to fetch and convert the URL to a data URL.");
          }
        });
    }

    setAppState({
      files: { ...appState.files, ...convertedFiles },
    });
  };

  const storeFilesInAppState = async (files: LibraryFileType[]) => {
    console.log("storeFilesInAppState:called", files);
    let convertedFiles: BinaryFiles = {};

    // Use map to create an array of promises
    const promises = files.map(async (file) => {
      // Check if the file is not already in appState.files
      if (!appState.files.hasOwnProperty(file.id)) {
        try {
          const result = await urlToDataUrl(file.url, file.mimeType);

          if (result) {
            convertedFiles[file.id as string] = {
              mimeType:
                file.mimeType ||
                (MIME_TYPES.binary as BinaryFileData["mimeType"]),
              id: file.id as BinaryFileData["id"],
              dataURL: result.dataUrl as BinaryFileData["dataURL"],
              created: file.created,
              isPublished: true,
            };
          } else {
            console.log("Failed to fetch and convert the URL to a data URL.");
          }
        } catch (error) {
          console.error("Error fetching and converting the URL:", error);
        }
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Update the state after all promises have resolved
    setAppState({
      files: { ...appState.files, ...convertedFiles },
    });
  };

  // called when click on more info button (open detail view of collecation)
  const selectDetailCollectionHandler = (data: CollocationType) => {
    data?.files?.length && storeFilesInAppState(data.files);
    setTemplateInfo(data);
  };

  const handleEditLibrary = (data: CollocationType) => {
    setEditLibData(data);
  };

  const fetchStockImages = async (data?: string) => {
    setIsLoading(true);
    const apiBody: StockImagesInput = {
      query: data ? data : lesson ? parts[0] : "Nature",
    };
    const images = await fetchStockImagesAPI(apiBody);
    setImages(images.results.photos);
    setIsLoading(false);
  };

  const debouncedChangeHandler = useMemo(
    () => throttle(fetchStockImages, LOAD_STOCK_IMAGE_TIMEOUT),
    [],
  );

  const fetchMyPublishedCollections = async () => {
    try {
      const lessonId = new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") as string;
      const user = await getUserInfo();
      const apiBody: {
        userId: string;
        sort: number;
      } = {
        userId: user.mail,
        sort: 1,
      };
      setIsLoading(true);
      const { result } = await fetchMyPublishedCollectionAPI(apiBody);
      setIsLoading(false);
      setMycollections(result);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const unpublishApprovalCollectionHandler = async (id: string) => {
    const apiBody: WithdrawRequestInput = {
      id,
    };
    await withdrawCollectionApprovalRequestAPI(apiBody);
    await fetchMyPublishedCollections();
    const user = await getUserInfo();
    const { result } = await fetchAllCollection({});
    setCollections(result.resources);
  };

  // @used for like unlike collection
  const likeUnlikeCollection = async (id: string) => {
    try {
      setIsLoading(true);
      const { result: likeData } = await likeUnlikeCollectionAPI(id);
      const collecation = [...collections];
      const myCollectionData = [...myCollections];

      const index = collecation.findIndex((item) => item.id === id);
      const MyCIndex = myCollectionData.findIndex((item) => item.id === id);
      const likeIndex = collecation.findIndex((item) => item.id === id);

      collecation[index] = likeData;
      myCollectionData[MyCIndex] = likeData;

      const likesData = collecation.filter(
        (item: CollocationType) => item.isLiked,
      );
      setLikeData(likesData);

      setCollections(collecation);
      setMycollections(myCollectionData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // @used for bookmark collection
  const bookmarkCollection = async (id: string) => {
    try {
      setIsLoading(true);
      const { result: bookmarkData } = await bookmarkCollectionAPI(id);
      const collecation = [...collections];
      const myCollectionData = [...myCollections];

      const index = collecation.findIndex((item) => item.id === id);
      const MyCIndex = myCollectionData.findIndex((item) => item.id === id);
      const likeIndex = collecation.findIndex((item) => item.id === id);

      collecation[index] = bookmarkData;
      myCollectionData[MyCIndex] = bookmarkData;

      const bookmarkedData = collecation.filter(
        (item: CollocationType) => item.isBookmark,
      );
      setBookmarkData(bookmarkedData);

      setCollections(collecation);
      setMycollections(myCollectionData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Dialog
      onCloseRequest={handleClose}
      className="libraryDialog p-0"
      isShowSidebarMenu={useIsMobile()}
      title={useIsMobile() ? t("collection.title") : ""}
      closeOnClickOutside={true}
      open={true}
      setOpen={() => false}
      setIsSideBarOpen={setIsSideBarOpen}
      isSideBarOpen={isSideBarOpen}
    >
      <div className="Header">
        <div
          className={`w-100 h-100 ${isMobile ? "p-2" : ""} ${
            content === "info"
              ? "templateInfo"
              : content === sidebarText().STOCKIMAGE
              ? "max_height"
              : ""
          }`}
        >
          {content !== "info" && content !== "editLib" && (
            <div
              className="d-flex flex-wrap h-100 gap-3"
              style={{ maxHeight: "100%" }}
            >
              <div className={` librarySidebar `}>
                {/* <h4 className="title d-lg-block d-none">
                  {t("collection.title")}
                </h4> */}
                <div className="overflow-auto sidebar-container">
                  {isSideBarOpen && (
                    <LibrarySidebar
                      changeTab={(val: string) => handleClick(val)}
                      isSideBarOpen={isSideBarOpen}
                      setIsSideBarOpen={setIsSideBarOpen}
                      currentTab={content}
                    />
                  )}
                </div>
              </div>
              <div
                className={` d-flex flex-column w-100 h-100`}
                style={{ maxHeight: "100%" }}
              >
                {content === sidebarText().TEMPLATE && (
                  <TemplateContent
                    handleClose={handleClose}
                    onInsertShape={onInsertShape}
                    collections={collections}
                    selectDetailCollectionHandler={
                      selectDetailCollectionHandler
                    }
                    changeTab={(val: string) => handleClick(val)}
                    likeUnlikeCollection={likeUnlikeCollection}
                    bookmarkCollection={bookmarkCollection}
                    filterOption={filterOption || {}}
                    filterDataWithKeys={filterDataWithKeys}
                    setFilterDataWithKeys={setFilterDataWithKeys}
                    fetchFilteredData={fetchFilteredData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    importLibraryFromUrl={importLibraryFromUrl}
                  />
                )}
                {content === sidebarText().STOCKIMAGE ? (
                  <StockImages
                    images={images}
                    insertImageOnCanvas={insertImageOnCanvas}
                    changeHandler={changeHandler}
                    searchVal={searchVal}
                    onClose={handleClose}
                    isLoading={isLoading}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                  />
                ) : (
                  <></>
                )}
                {content === sidebarText().MYCOLLECTIONS && (
                  <MyCollection
                    myCollections={myCollections}
                    unpublishApprovalCollectionHandler={
                      unpublishApprovalCollectionHandler
                    }
                    isLoading={isLoading}
                    likeUnlikeCollection={likeUnlikeCollection}
                    bookmarkCollection={bookmarkCollection}
                    fetchFilteredData={fetchFilteredData}
                    filterData={filterData}
                    filterDataWithKeys={filterDataWithKeys}
                    filterOption={filterOption || {}}
                    setFilterData={setFilterData}
                    setFilterDataWithKeys={setFilterDataWithKeys}
                    setIsLoading={setIsLoading}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                  />
                )}
                {content === sidebarText().IMAGES && (
                  <Images
                    insertImageOnCanvas={insertImageOnCanvas}
                    onClose={handleClose}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                  />
                )}
                {content === sidebarText().VIDEOS && (
                  <Videos
                    insertVideoOnCanvas={insertVideoOnCanvas}
                    onClose={handleClose}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    elements={elements}
                    subscription={subscription}
                  />
                )}
                {content === sidebarText().AUDIOS && (
                  <Audios
                    insertAudioOnCanvas={insertAudioOnCanvas}
                    onClose={handleClose}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    appState={appState}
                    elements={elements}
                    subscription={subscription}
                  />
                )}
                {content === sidebarText().UNDERREVIEW && (
                  <UnderReview
                    underReviewData={underReviewData}
                    withdrawCollectionApprovalRequest={
                      withdrawCollectionApprovalRequest
                    }
                    handleEditLibData={handleEditLibrary}
                    changeTab={(val: string) => handleClick(val)}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    fetchFilteredData={fetchFilteredData}
                    filterData={filterData}
                    filterDataWithKeys={filterDataWithKeys}
                    filterOption={filterOption}
                    setFilterData={setFilterData}
                    setFilterDataWithKeys={setFilterDataWithKeys}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    onInsertShape={onInsertShape}
                    closeLibraryDialog={onClose}
                  />
                )}
                {content === sidebarText().LIKED && (
                  <Like
                    likeData={likeData}
                    selectDetailCollectionHandler={
                      selectDetailCollectionHandler
                    }
                    changeTab={(val: string) => handleClick(val)}
                    likeUnlikeCollection={likeUnlikeCollection}
                    bookmarkCollection={bookmarkCollection}
                    isLoading={isLoading}
                    fetchFilteredData={fetchFilteredData}
                    filterData={filterData}
                    filterDataWithKeys={filterDataWithKeys}
                    filterOption={filterOption}
                    setFilterData={setFilterData}
                    setFilterDataWithKeys={setFilterDataWithKeys}
                    setIsLoading={setIsLoading}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    handleClose={handleClose}
                    importLibraryFromUrl={importLibraryFromUrl}
                  />
                )}
                {content === sidebarText().BOOKMARK && (
                  <Bookmark
                    bookmarkData={bookmarkData}
                    selectDetailCollectionHandler={
                      selectDetailCollectionHandler
                    }
                    changeTab={(val: string) => handleClick(val)}
                    likeUnlikeCollection={likeUnlikeCollection}
                    bookmarkCollection={bookmarkCollection}
                    isLoading={isLoading}
                    fetchFilteredData={fetchFilteredData}
                    filterData={filterData}
                    filterDataWithKeys={filterDataWithKeys}
                    filterOption={filterOption}
                    setFilterData={setFilterData}
                    setFilterDataWithKeys={setFilterDataWithKeys}
                    setIsLoading={setIsLoading}
                    isSideBarOpen={isSideBarOpen}
                    setIsSideBarOpen={setIsSideBarOpen}
                    handleClose={handleClose}
                    importLibraryFromUrl={importLibraryFromUrl}
                  />
                )}
              </div>
            </div>
          )}
          {content === "editLib" && (
            <>
              <h6>
                <ToolButton
                  key="newPage"
                  type={ToolButtonEnum.BUTTON}
                  title={t("labels.back")}
                  aria-label={t("labels.back")}
                  icon={backIcon}
                  className="backIcon"
                  onClick={() => handleClick(sidebarText().TEMPLATE)}
                >
                  <span className="back">{t("labels.back")}</span>
                </ToolButton>
              </h6>
              {editLibData && (
                <EditLibrary
                  handleClose={handleClose}
                  changeTab={(val: string) => handleClick(val)}
                  libraryData={editLibData}
                />
              )}
            </>
          )}{" "}
          {content === "info" && (
            <>
              <h6>
                <ToolButton
                  key="newPage"
                  type={ToolButtonEnum.BUTTON}
                  title={t("labels.back")}
                  aria-label={t("labels.back")}
                  icon={backIcon}
                  className="backIcon"
                  onClick={() => handleClick(sidebarText().TEMPLATE)}
                >
                  <span className="back">{t("labels.back")}</span>
                </ToolButton>
              </h6>
              {templateInfo && (
                <TemplateInfo
                  handleClose={handleClose}
                  onInsertShape={onInsertShape}
                  templateInfo={templateInfo}
                  likeUnlikeCollection={likeUnlikeCollection}
                  selectDetailCollectionHandler={selectDetailCollectionHandler}
                  changeTab={(val: string) => handleClick(val)}
                  bookmarkCollection={bookmarkCollection}
                  appState={appState}
                  setAppState={setAppState}
                  setLibraryItems_={setLibraryItems}
                  libraryItems_={libraryItems}
                  editCollection={editCollection}
                  importLibraryFromUrl={importLibraryFromUrl}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};
