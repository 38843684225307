import React, { useContext, useState } from "react";
import { CheckboxItem } from "../../../../../../../CheckboxItem";
import { getLessonId } from "../../../../../../../../excalidraw-app/api/getuserInfo";
import { SpinnerContext } from "../../../../../../contexts";
import Loader from "../../../../../../../../App/shareComponent/Loader";
import { createSpinWheel } from "../../../../../../../../excalidraw-app/api/collection";
import { t } from "i18next";

interface Props {
  setOpen: (value: boolean) => void;
  // canvasRootId: string;
  onClose: () => void;
  results: string[];
  // insertImageOnCanvas: (file: File) => void;
}

const AddSpinnerWheel = ({ setOpen, onClose, results }: Props) => {
  const { segments, timeDuration, isTimer } = useContext(SpinnerContext);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [selected, setSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<{
    isSaveAndExit: boolean;
    isSaveAndContinue: boolean;
  }>({
    isSaveAndExit: false,
    isSaveAndContinue: false,
  });
  const [error, setError] = useState<{ title: string; wheel: string }>({
    title: "",
    wheel: "",
  });
  const [spinnerWheelData, setSpinnerWheelData] = useState<any>({
    title: "",
    description: "",
    isPublic: false,
  });

  const onChange = (e: { target: { name: any; value: any } }) => {
    setSpinnerWheelData({
      ...spinnerWheelData,
      [e.target.name]: e.target.value,
    });
  };

  const onSave = async (isExit: boolean) => {
    try {
      if (spinnerWheelData.title === "") {
        setError({ ...error, title: "Title is required" });
        return;
      }
      if (!segments.some((obj) => obj.segment.length > 0)) {
        setError({ ...error, wheel: "At least one wheel must be filled." });
        return;
      }
      setIsLoading({
        isSaveAndExit: isExit ? true : false,
        isSaveAndContinue: isExit ? false : true,
      });
      const lessonId = await getLessonId();
      const response = await createSpinWheel({
        ...spinnerWheelData,
        wheels: segments?.filter((data) => data.segment.length > 0),
        isTimer: isTimer,
        timerDuration: timeDuration,
        userId: user?.username ? user.username : user.mail,
        slug: user.slug,
        lessonId: lessonId,
      });
      if (response?.status === "Success" || response?.status === "success") {
        setIsLoading({
          isSaveAndExit: false,
          isSaveAndContinue: false,
        });
        sessionStorage.setItem(
          "spinWheel",
          JSON.stringify({ segments, isTimer, timeDuration, results }),
        );
        setOpen(false);
        if (isExit) {
          onClose();
        }
      }
    } catch (error) {
      setIsLoading({
        isSaveAndExit: false,
        isSaveAndContinue: false,
      });
      console.log("error", error);
    }
  };

  return (
    <div>
      <div>
        <h5>Add spinner wheel</h5>
      </div>

      <div className="mt-3">
        <div className="">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            required
            onChange={(e) => {
              onChange(e);
              setError({ ...error, title: "" });
            }}
          />
        </div>
        <p className="fs-small text-danger mb-0">{error.title}</p>
        <div className="mt-2">
          <label>Description</label>
          <input
            type="text"
            className="form-control"
            name="description"
            onChange={onChange}
          />
        </div>

        <div className="mt-3 d-inline-block">
          <CheckboxItem
            checked={selected}
            onChange={(checked, event) => {
              setSelected(checked);
              setSpinnerWheelData({ ...spinnerWheelData, isPublic: checked });
            }}
            className="ispublic-checkbox"
          >
            <span>Public</span>
          </CheckboxItem>
        </div>
        <p className="fs-small text-danger mb-0">{error.wheel}</p>
        <div className="d-flex justify-content-end gap-3">
          {/* <button
            className={`btn mt-3 d-flex gap-1`}
            // disabled={!responsePrompt}
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>{t("previewDialog.cancel")}</span>
          </button> */}
          <button
            className={`btn-insert btn-save mt-3 d-flex gap-1`}
            // disabled={!responsePrompt}
            onClick={() => onSave(false)}
          >
            {isLoading.isSaveAndContinue ? (
              <Loader className="h-100" />
            ) : (
              <span>Save and continue</span>
            )}
          </button>
          <button
            className={`btn-insert btn-save mt-3 d-flex gap-1`}
            // disabled={!responsePrompt}
            onClick={() => onSave(true)}
          >
            {isLoading.isSaveAndExit ? (
              <Loader className="h-100" />
            ) : (
              <span>Save and exit</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddSpinnerWheel;
