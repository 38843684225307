import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  fetchWBLocalAudios,
  uploadWBLocalAudios,
} from "../../../../../../excalidraw-app/api/collection";
import { CardWithImage } from "../../../../components/card";
import Loader from "../../../../../../App/shareComponent/Loader";
import { MediaHandlersRef } from "../../CollectionsTab";
import { MediaCollectionProps } from "../imageCollection";
import { NonDeletedExcalidrawElement } from "../../../../../../element/types";
import { Subscription } from "../../../../../../types";
import { deleteWbLocalMedia } from "../../../../../../excalidraw-app/api/userAPI";
import { Toast, ToastType } from "../../../../../Toast";
import { t } from "../../../../../../i18n";

interface audioType {
  url: string;
  name: string;
  isSelected: boolean;
  createdAt: string;
  fileSize: string;
}

export const AudioCollection = forwardRef<
  MediaHandlersRef,
  MediaCollectionProps & {
    subscription: Subscription | undefined;
    elements: NonDeletedExcalidrawElement[];
  }
>(
  (
    {
      insertOnCanvas,
      setAppState,
      setDeleteElement,
      deleteElement,
      subscription,
      elements,
      searchVal,
    },
    ref,
  ) => {
    const recordLimit = (subscription && subscription.videoRecordLimit) || 40;
    const [audios, setAudios] = useState<audioType[]>([]);
    const [filteredAudios, setFilteredAudios] = useState<audioType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState<any>({});
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [currentBlobName, setCurrentBlobName] = useState<string>("");
    const [isExceedAudioLimit, setIsExceedAudioLimit] = useState<boolean>(
      false,
    );
    const [alreadyExistAudio, setAlreadyExistAudio] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      onDelete: handleDelete,
      onUpload: onAudioChange,
    }));

    const fetchAudioCollection = async () => {
      try {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem("user") || "");

        const { results } = await fetchWBLocalAudios(user.mail);

        const allLocalAudios = results.reduce(
          (acc: string | any[], curr: { localAudios: any }) => {
            return acc.concat(curr.localAudios);
          },
          [],
        );
        setAudios(allLocalAudios);
        setRecord(results[0] ? results[0] : {});
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    useEffect(() => {
      fetchAudioCollection();
    }, []);

    useEffect(() => {
      if (searchVal) {
        const searchResult = audios.filter((audio) =>
          audio.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredAudios(searchResult);
      }
      setFilteredAudios(audios);
    }, [searchVal, audios]);

    const insertAudio = async (file: File) => {
      if (!file) return;
      const perPageAudioLimit = subscription ? subscription?.audioLimit : 0;

      const nonDeletedAudio = elements.filter(
        (data) => data.type === "audio" && data.isDeleted === false,
      );
      setIsExceedAudioLimit(
        nonDeletedAudio.length < Number(perPageAudioLimit) ? false : true,
      );

      if (nonDeletedAudio.length < Number(perPageAudioLimit)) {
        insertOnCanvas(file);
        setIsLoading(false);
        setAppState({ isLibraryOpen: false });
      } else {
        setToastMessage("");
      }
    };

    const onAudioChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      debugger;
      const selectedFile = event.target.files?.[0];
      const orignalFileName = selectedFile?.name.replace(/\s+/g, "_");

      const getSameNameFile = audios.map((audio, i) => {
        if (audio.name === orignalFileName) {
          setToastMessage("");
          setAlreadyExistAudio(true);
        }
      });

      if (selectedFile) {
        const audio = document.createElement("audio");
        audio.src = URL.createObjectURL(selectedFile);

        audio.onloadedmetadata = async () => {
          debugger;
          if (audio.duration > Number(recordLimit)) {
            setToastMessage(
              `Upload failed: The audio file exceeds the ${recordLimit}-second limit. Please upload an audio file within the allowed duration`,
            );
            return;
          } else {
            try {
              debugger;
              const file_ = event.target.files?.[0];
              const lesson = JSON.parse(localStorage.getItem("lesson") || "");

              const user = JSON.parse(localStorage.getItem("user") || "");
              if (file_ && lesson && user.mail) {
                const formData = new FormData();
                var fileSizeMB = (file_.size / (1024 * 1024)).toFixed(2);

                formData.append("file", file_);
                formData.append("classId", lesson?.ClassID as string);
                formData.append("mail", user.mail);
                formData.append("fileSize", fileSizeMB);

                setIsLoading(true);
                await uploadWBLocalAudios(formData);
                await fetchAudioCollection();
                setIsLoading(false);
              }
            } catch (err) {
              console.log(err);
              setToastMessage("Error in uploading audio");
            }
          }
          return;
        };
      }
    };

    const handleDelete = async () => {
      try {
        const body = {
          id: record.id,
          classId: record.classId,
          email: record.email,
          type: "audio",
          blobNames: deleteElement
            ? deleteElement
            : currentBlobName
            ? [currentBlobName]
            : [],
          typeOfDelete: "single",
        };
        await deleteWbLocalMedia(body);
        let arr: audioType[] = [];
        const deleteAudioFromVideos = filteredAudios.filter((audio) => {
          if (!deleteElement?.includes(audio.name)) {
            arr.push({ ...audio });
          }
        });
        setFilteredAudios(arr);
      } catch (error) {
        console.log("error in delete audio", error);
      }
    };

    const user = JSON.parse(localStorage.getItem("user") || "");
    return (
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          filteredAudios.map((audio, index) => {
            const nameWithoutExtension = audio.name
              .split(".")
              .slice(0, -1)
              .join(".");
            return (
              <CardWithImage
                key={index}
                created={audio.createdAt}
                images={audio.url}
                name={nameWithoutExtension}
                userProfile={user?.UrlPhoto}
                userName={user?.name}
                type="audio"
                buttonText={t("cards.importOnCanvas")}
                likeBookmarkIcon={false}
                isHoverPreviewBtns={false}
                onDelete={() => {
                  if (deleteElement && deleteElement.includes(audio.name)) {
                    setDeleteElement &&
                      setDeleteElement((prev) =>
                        prev.filter((el) => el !== audio.name),
                      );
                    setCurrentBlobName("");
                  } else {
                    setDeleteElement &&
                      setDeleteElement((prev) => [...prev, audio.name]);
                    setCurrentBlobName(audio.name);
                  }
                }}
                isDelete={true}
                isHoverUseThisTemplateBtn={false}
                onAddtoCollectionBtnClick={() => {
                  setIsLoading(true);
                  fetch(audio.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const extension = audio.url.split(".").pop();
                      // You can use a mapping of file extensions to MIME types if needed
                      // For simplicity, here we just use a basic mapping for common audio types
                      const mimeTypes: {
                        [key: string]: string;
                      } = {
                        mp3: "audio/mpeg",
                        ogg: "audio/ogg",
                        wav: "audio/wav",
                        // Add more as needed
                      };
                      const type = extension
                        ? mimeTypes[extension?.toLowerCase()]
                        : "audio/mpeg";
                      const audioFile = new File([blob], "audio.mp3", {
                        type: type,
                      });
                      insertAudio(audioFile);
                      setIsLoading(false);
                    })
                    .catch((error) => {
                      setIsLoading(false);
                      console.error("Error loading audio:", error);
                    });
                }}
              />
            );
          })
        )}
        {isExceedAudioLimit && toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={`You've exceeded the limit You're allowed to upload a maximum of ${
              subscription ? subscription?.audioLimit : 0
            } audios per page.`}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}

        {alreadyExistAudio && toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={`The selected file name already exists. Please choose a unique file name to proceed.`}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
        {!isExceedAudioLimit && toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={toastMessage}
            clearToast={() => setToastMessage(null)}
            className="style"
            style={{
              left: "36%",
            }}
          />
        )}
      </div>
    );
  },
);
