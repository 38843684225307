import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import { actionToggleGridMode, actionToggleZenMode } from "../../actions";
import { actionToggleViewMode } from "../../actions/actionToggleViewMode";
import { ActionManager } from "../../actions/manager";
import {
  getShortcutFromShortcutName,
  ShortcutName,
} from "../../actions/shortcuts";
import { NonDeletedExcalidrawElement } from "../../element/types";
import { t } from "../../i18n";
import { AppState } from "../../types";
import { useIsMobile } from "../App";
import { Popover } from "../Popover";
import "./style.scss";

type SettingProps = {
  actionManager: ActionManager;
  setOpenSettingPopover: Dispatch<SetStateAction<boolean>>;
  appState: AppState;
  left: number;
  top: string;
  elements: readonly NonDeletedExcalidrawElement[];
};

export const Settings = ({
  actionManager,
  setOpenSettingPopover,
  appState,
  left,
  top,
  elements,
}: SettingProps) => {
  const viewModeOptions = [
    actionToggleGridMode,
    actionToggleZenMode,
    actionToggleViewMode,
  ];
  const isMobile = useIsMobile();
  return (
    <Popover {...(!isMobile ? { left, top } : {})}>
      <div className="py-2" onContextMenu={(event) => event.preventDefault()}>
        {viewModeOptions.map((option, idx) => {
          if (typeof option === "string" && option === "separator") {
            return <hr key={idx} className="context-menu-option-separator" />;
          }

          const actionName = option.name;
          // const label = option.contextItemLabel
          //   ? t(option.contextItemLabel)
          //   : "";
          let label = "";
          if (option.contextItemLabel) {
            if (typeof option.contextItemLabel === "function") {
              label = t(
                option.contextItemLabel(elements, appState, actionManager.app),
              );
            } else {
              label = t(option.contextItemLabel);
            }
          }
          return (
            <li
              key={idx}
              data-testid={actionName}
              className="d-flex"
              onClick={() => setOpenSettingPopover(false)}
            >
              <button
                className={clsx("context-menu-option", {
                  dangerous: actionName === "deleteSelectedElements",
                  checkmark: option.checked?.(appState),
                })}
                onClick={() => actionManager.executeAction(option)}
              >
                <div className="context-menu-option__label">{label}</div>
                {actionName &&
                  getShortcutFromShortcutName(actionName as ShortcutName) && (
                    <kbd className="context-menu-option__shortcut">
                      {getShortcutFromShortcutName(actionName as ShortcutName)}
                    </kbd>
                  )}
              </button>
            </li>
          );
        })}
      </div>
    </Popover>
  );
};
