import { apiDelete, apiDelete_, apiGet, apiPost, apiPut } from ".";
import {
  StockImagesInput,
  WithdrawRequestInput,
} from "../../components/LibraryDialog";
import { FilteredValueType } from "../../components/libraryItemsDialog/tabs/collections/CollectionsTab";
import { FileId } from "../../element/types";
import { API_URL, ACV_API_URL } from "./constant";
const slug = new URLSearchParams(window.location.search).get("slug");
const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

export const fetchAllCollection = ({
  search,
  sort,
  tab,
  limit = 10,
  offset = 0,
}: {
  search?: string;
  sort?: number;
  tab?: string;
  limit?: number;
  offset?: number;
}) =>
  apiGet(
    `${ACV_API_URL}/api/library/collection${slug ? "?slug=" + slug : ""}${
      search ? "&search=" + search : ""
    }&sort=${sort ? sort : 1}${
      tab ? "&tab=" + tab : ""
    }&userEmail=${userMail}&limit=${limit}&offset=${offset}`,
  );

export const fetchTemplatesFilterOptions = () =>
  apiGet(`${ACV_API_URL}/api/library/filtered-collection-option`);

export const fetchFilteredCollectionData = (body: FilteredValueType) => {
  const queryString = `?offset=${body.offset}&limit=${10}&search=${
    body.search ?? ""
  }&age=${body.age ?? ""}&author=${body.author ?? ""}&tags=${body.tags ?? ""}`;
  return apiGet(`${ACV_API_URL}/api/library/filtered-collection${queryString}`);
};

export const fetchUnderReviewCollectionsAPI = ({
  userEmail,
  search,
  sort,
  limit = 10,
  offset = 0,
}: {
  userEmail: string;
  search?: string;
  sort?: number;
  limit?: number;
  offset?: number;
}) =>
  apiGet(
    `${ACV_API_URL}/api/library/underReview-collection${
      slug ? "?slug=" + slug : ""
    }${search ? "&search=" + search : ""}&sort=${
      sort ? sort : 1
    }&userEmail=${userEmail}&limit=${limit}&offset=${offset}`,
  );

export const fetchUserActionCollectionsAPI = ({
  userEmail,
  userAction,
  search,
  sort,
  limit = 10,
  offset = 0,
}: {
  userEmail: string;
  userAction: string;
  search?: string;
  sort?: number;
  limit?: number;
  offset?: number;
}) =>
  apiGet(
    `${ACV_API_URL}/api/library/user-activity-collection${
      slug ? "?slug=" + slug : ""
    }${userAction ? "&userAction=" + userAction : ""}${
      search ? "&search=" + search : ""
    }&sort=${
      sort ? sort : 1
    }&userEmail=${userEmail}&limit=${limit}&offset=${offset}`,
  );

export const withdrawCollectionApprovalRequestAPI = (id: string) =>
  apiDelete(`${ACV_API_URL}/api/library/withdraw-collection/${id}`);

export const removeUndpublishedCollectionRequestAPI = (
  ids: string[],
  userId: string,
  lessonId: string,
) => {
  apiDelete_(`${API_URL}/remove-unpublished-collection`, {
    ids,
    lessonId,
    userId,
  });
};

export const fetchStockImagesAPI = (body: StockImagesInput) =>
  apiPost(`${API_URL}/get-stock-images`, body);

export const fetchMyPublishedCollectionAPI = ({
  userEmail,
  search,
  sort,
  limit = 10,
  offset = 0,
}: {
  userEmail: string;
  search?: string;
  sort?: number;
  limit?: number;
  offset?: number;
}) => {
  return apiGet(
    `${ACV_API_URL}/api/library/mypublished-collection${
      slug ? "?slug=" + slug : ""
    }${search ? "&search=" + search : ""}&sort=${
      sort ? sort : 1
    }&userEmail=${userEmail}&limit=${limit}&offset=${offset}`,
  );
};

export const fetchWBLocalImages = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-images?email=${mail}`);

export const fetchWBLocalVideos = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-videos?email=${mail}`);
export const fetchWBLocalAudios = (mail: string) =>
  apiGet(`${API_URL}/get-wb-collection-audios?email=${mail}`);

export const uploadWBLocalImages = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-image`, body);

export const uploadWBLocalVideos = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-video`, body);

export const uploadWBLocalAudios = (body: any) =>
  apiPost(`${API_URL}/upload-whiteboard-audio`, body);

export const likeUnlikeCollectionAPI = (id: string) =>
  apiPut(`${API_URL}/like-unlike-collection?id=${id}`);

export const userActionCollectionAPI = (body: {
  collectionId: string;
  userEmail: string;
  userAction: string;
}) => apiPost(`${ACV_API_URL}/api/library/user-collection-activity`, body);

export const bookmarkCollectionAPI = (id: string) =>
  // apiPut(`localhost:8080/bookmark-collection?id=${id}`);
  apiPut(`${API_URL}/bookmark-collection?id=${id}`);

export const postLocalStateLibray = (body: any) =>
  apiPost(`${API_URL}/post-localState-library`, body);

export const getLocalStateLibray = (body: any) =>
  apiGet(
    `${API_URL}/get-localState-library?lessonId=${body.lessonId}&userId=${body.userId}`,
  );

export const getPublishedLibrariesFiles = (lessonId: string, fileId: FileId) =>
  apiGet(
    `${ACV_API_URL}/api/library/published-collection-files?lessonId=${lessonId}&fileId=${fileId}`,
  );

export const deleteFiles = (fileId: FileId, type: string, lessonId: string) =>
  apiDelete(
    `${API_URL}/delete-files?fileId=${fileId}&type=${type}&lessonId=${lessonId}`,
  );

export const getSpinWheels = (body: any) =>
  apiPost(`${API_URL}/spin-wheel/get`, body);

export const deleteSpinWheel = (id: string) =>
  apiDelete(`${API_URL}/spin-wheel/remove/${id}`);

export const createSpinWheel = (body: any) =>
  apiPost(`${API_URL}/spin-wheel/create`, body);

export const getCategories = (userId: string, slug: string) =>
  apiGet(
    `${ACV_API_URL}/api/collection-category?userEmail=${userId}&slug=${slug}`,
  );

export const getUserTemplateCategories = (
  userEmail: string,
  slug: string,
  collectionId: string,
) =>
  apiGet(
    `${ACV_API_URL}/api/collection-category/get-user-category-collection?userEmail=${userEmail}&slug=${slug}&collectionId=${collectionId}`,
  );

export const deleteCategory = (id: string) =>
  apiDelete(`${ACV_API_URL}/api/collection-category/${id}`);

export const createCategory = (body: any) =>
  apiPost(`${ACV_API_URL}/api/collection-category`, body);

export const editCategory = (body: any) =>
  apiPut(`${ACV_API_URL}/api/collection-category`, body);

export const updateLibraryTemplateCategory = (body: any) =>
  apiPost(`${ACV_API_URL}/api/collection-category/user`, body);

export const getCategoryWiseLibraryTemplate = (
  slug: string,
  userEmail: string,
  categoryId: string,
) =>
  apiGet(
    `${ACV_API_URL}/api/collection-category/get-category-wise-collection?slug=${slug}&userEmail=${userEmail}&categoryId=${categoryId}`,
  );

export const removeCategoryFromLibraryTemplate = (
  collectionId: string,
  userEmail: string,
  slug: string,
  categoryId: string,
) =>
  apiDelete(
    `${ACV_API_URL}/api/collection-category/remove-category-from-collection?slug=${slug}&collectionId=${collectionId}&userEmail=${userEmail}&categoryId=${categoryId}`,
  );
