import axios from "axios";
export const checkUserAuth = () => localStorage.getItem("access_token");
export function getHeaders() {
  const tokenData = localStorage.getItem("access_token");
  // const parsedToken = JSON.parse(tokenData);
  // eslint-disable-next-line no-console
  if (!tokenData) {
    return "";
  }
  const headers = {
    Authorization: `Bearer ${tokenData}` || "",
    "Content-Type": "application/json",
  };
  return headers;
}
export function apiReq(endPoint, data, method, headers, requestOptions = {}) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-param-reassign

    headers = {
      ...getHeaders(),
      ...headers,
    };

    if (method === "get" || method === "delete") {
      // eslint-disable-next-line no-param-reassign
      data = {
        ...requestOptions,
        params: data,
        headers,
        data: {},
      };
    }

    axios[method](endPoint, data, headers)
      .then((result) => {
        // eslint-disable-next-line no-shadow
        const { data } = result;
        if (data.status === false) {
          return reject(data);
        }
        return resolve(data);
      })
      .catch((error) => reject(error));
  });
}
export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "get", headers, requestOptions);
}
export function apiPost(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "post", headers, requestOptions);
}
export function apiPatch(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "patch", headers, requestOptions);
}
export function apiPut(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "put", headers, requestOptions);
}
export function apiDelete(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "delete", headers, requestOptions);
}

export function getStudents(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "get", headers, requestOptions);
}

export function apiDelete_(endPoint, data, headers = {}, requestOptions = {}) {
  // Merge default headers with any additional headers provided
  const defaultHeaders = {
    'Content-Type': 'application/json', // Ensure JSON content type
    ...headers,
  };

  // Update requestOptions to include the body
  const options = {
    method: 'DELETE',
    headers: defaultHeaders,
    body: JSON.stringify(data), // Convert the data (like 'ids') to JSON string
    ...requestOptions,
  };

  // Use fetch or your existing apiReq method
  return fetch(endPoint, options)
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}

