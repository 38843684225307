import ReactTooltip from "react-tooltip";
import { trackEvent } from "../analytics";
import { ActiveFile } from "../components/ActiveFile";
import { useIsMobile } from "../components/App";
import { CheckboxItem } from "../components/CheckboxItem";
import { DarkModeToggle } from "../components/DarkModeToggle";
import { ProjectName } from "../components/ProjectName";
import { ToolButton, ToolButtonEnum } from "../components/ToolButton";
import "../components/ToolIcon.scss";
import { load, questionCircle, saveAs } from "../components/icons";
import { DEFAULT_EXPORT_PADDING, EXPORT_SCALES, THEME } from "../constants";
import { loadFromJSON, saveAsJSON } from "../data";
import { isImageFileHandle } from "../data/blob";
import { nativeFileSystemSupported } from "../data/filesystem";
import { saveAsJSONZip } from "../data/json";
import { resaveAsImageWithScene } from "../data/resave";
import { getNonDeletedElements } from "../element";
import { Theme } from "../element/types";
import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { onSaveElementInDB } from "../excalidraw-app/api/userAPI";
import { STORAGE_KEYS } from "../excalidraw-app/data/localStorage";
import { t } from "../i18n";
import { KEYS } from "../keys";
import { getSelectedElements, isSomeElementSelected } from "../scene";
import { getExportSize } from "../scene/export";
import { register } from "./register";
import { nanoid } from "nanoid";

export const actionChangeProjectName = register({
  name: "changeProjectName",
  perform: (_elements, appState, value) => {
    trackEvent("change", "title");
    return { appState: { ...appState, name: value }, commitToHistory: false };
  },
  PanelComponent: ({ appState, updateData, appProps }) => (
    <ProjectName
      label={t("labels.fileTitle")}
      value={appState.name || "Unnamed"}
      onChange={(name: string) => updateData(name)}
      isNameEditable={
        typeof appProps.name === "undefined" && !appState.viewModeEnabled
      }
    />
  ),
});

export const actionChangeExportScale = register({
  name: "changeExportScale",
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportScale: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ elements: allElements, appState, updateData }) => {
    const elements = getNonDeletedElements(allElements);
    const exportSelected = isSomeElementSelected(elements, appState);
    const exportedElements = exportSelected
      ? getSelectedElements(elements, appState)
      : elements;

    return (
      <>
        {EXPORT_SCALES.map((s) => {
          const [width, height] = getExportSize(
            exportedElements,
            DEFAULT_EXPORT_PADDING,
            s,
          );

          const scaleButtonTitle = `${t(
            "buttons.scale",
          )} ${s}x (${width}x${height})`;

          return (
            <ToolButton
              key={s}
              size="small"
              type="radio"
              icon={`${s}x`}
              name="export-canvas-scale"
              title={scaleButtonTitle}
              aria-label={scaleButtonTitle}
              id="export-canvas-scale"
              checked={s === appState.exportScale}
              onChange={() => updateData(s)}
            />
          );
        })}
      </>
    );
  },
});

export const actionChangeExportBackground = register({
  name: "changeExportBackground",
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportBackground: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <CheckboxItem
      checked={appState.exportBackground}
      onChange={(checked) => updateData(checked)}
      className="justify-content-start"
    >
      {t("labels.withBackground")}
    </CheckboxItem>
  ),
});

export const actionChangeExportEmbedScene = register({
  name: "changeExportEmbedScene",
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportEmbedScene: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <CheckboxItem
      checked={appState.exportEmbedScene}
      onChange={(checked) => updateData(checked)}
    >
      {t("labels.exportEmbedScene")}
      <ReactTooltip
        place="bottom"
        effect="solid"
        multiline={true}
        // type="success"
        id="exportEmbedScene_details_icon"
      >
        {t("labels.exportEmbedScene_details")}
      </ReactTooltip>
      {/* <Tooltip label={t("labels.exportEmbedScene_details")} long={true}> */}
      <div
        className="acv-tooltip-icon"
        // id="exportEmbedScene_details_icon"
        data-tip="React-tooltip"
        data-for="exportEmbedScene_details_icon"
      >
        {questionCircle}
      </div>
      {/* </Tooltip> */}
    </CheckboxItem>
  ),
});

export const actionSaveToActiveFile = register({
  name: "saveToActiveFile",
  perform: async (elements, appState, value, app) => {
    const fileHandleExists = !!appState.fileHandle;

    try {
      const { fileHandle } = isImageFileHandle(appState.fileHandle)
        ? await resaveAsImageWithScene(elements, appState, appState.files)
        : await saveAsJSON(elements, appState, appState.files);

      return {
        commitToHistory: false,
        appState: {
          ...appState,
          fileHandle,
          toastMessage: fileHandleExists
            ? fileHandle?.name
              ? t("toast.fileSavedToFilename").replace(
                  "{filename}",
                  `"${fileHandle.name}"`,
                )
              : t("toast.fileSaved")
            : null,
        },
      };
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event[KEYS.CTRL_OR_CMD] && !event.shiftKey,
  PanelComponent: ({ updateData, appState }) => (
    <ActiveFile
      onSave={() => updateData(null)}
      fileName={appState.fileHandle?.name}
    />
  ),
});

export const actionSaveFileToDisk = register({
  name: "saveFileToDisk",
  perform: async (elements, appState) => {
    try {
      const pages = appState.DBElements;
      const selectedPages = JSON.parse(
        localStorage.getItem("selectedPages") || "[]",
      );
      if (selectedPages.length > 1) {
        const filteredPages = pages.filter((page: any) =>
          selectedPages.includes(page.page),
        );
        await saveAsJSONZip(
          filteredPages,
          {
            ...appState,
            fileHandle: null,
          },
          appState.files,
        );
        return {
          commitToHistory: false,
          appState: { ...appState },
        };
      } else {
        const findedPage: any = pages.find(
          (page: any) => Number(page.page) === Number(selectedPages[0]),
        );
        const elements_ =
          selectedPages.length > 0 && findedPage ? findedPage.data : elements;
        const { fileHandle } = await saveAsJSON(
          elements_,
          {
            ...appState,
            fileHandle: null,
          },
          appState.files,
        );
        return {
          commitToHistory: false,
          appState: { ...appState, fileHandle },
        };
      }
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={saveAs}
      title={t("buttons.saveAs")}
      aria-label={t("buttons.saveAs")}
      showAriaLabel={useIsMobile()}
      hidden={!nativeFileSystemSupported}
      onClick={() => updateData(null)}
      data-testid="save-as-button"
    />
  ),
  contextItemLabel: "labels.saveFileToDisk",
});

export const actionLoadScene = register({
  name: "loadScene",
  perform: async (elements, appState) => {
    try {
      const {
        elements: loadedElements,
        appState: loadedAppState,
        files,
      } = await loadFromJSON(appState, elements);
      const mergeLoadedElements = elements.concat(loadedElements);
      const lessonId = await getLessonId();
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const elementsWithLessonId = mergeLoadedElements.map((element) => {
        return {
          ...element,
          lessonId: lessonId || element.lessonId,
          id: nanoid(),
        };
      });

      localStorage.setItem(
        STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
        JSON.stringify(elementsWithLessonId),
      );

      if (user && lessonId) {
        // timeout for waiting for load elements on canvas
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: true },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        setTimeout(async () => {
          await onSaveElementInDB(
            appState.currentPage,
            lessonId,
            appState.DBElements,
            appState.viewBackgroundColor,
          );
          window.parent.postMessage(
            { type: "STORE_ELEMENTS", isLoading: false },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
        }, 500);
      }

      return {
        elements: elementsWithLessonId,
        appState: { ...loadedAppState, files },
        commitToHistory: true,
      };
    } catch (error) {
      return {
        elements,
        // appState: { ...appState, errorMessage: error.message },
        commitToHistory: false,
      };
    }
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={load}
      title={t("buttons.load")}
      aria-label={t("buttons.load")}
      showAriaLabel={useIsMobile()}
      onClick={updateData}
      data-testid="load-button"
    />
  ),
});

export const actionExportWithDarkMode = register({
  name: "exportWithDarkMode",
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportWithDarkMode: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <div
      style={{
        display: "flex",
        // justifyContent: "flex-end",
        // marginTop: "-45px",
        marginBottom: "-40px",
      }}
    >
      <DarkModeToggle
        value={appState.exportWithDarkMode ? THEME.DARK : THEME.LIGHT}
        onChange={(theme: Theme) => {
          updateData(theme === THEME.DARK);
        }}
        title={t("labels.toggleExportColorScheme")}
      />
    </div>
  ),
});
