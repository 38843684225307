import { Node, Editor } from "@tiptap/core";

export const TextwrapExtension = Node.create({
  name: "textwrap",
  defaultOptions: {
    types: ["textStyle"],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textwrap: {
            renderHTML: (attributes) => {
              return {
                style: `white-space: ${
                  attributes.textwrap?.textwrap || attributes.textwrap
                };`,
              };
            },
            parseHTML: (element) => {
              const clonedStyles = element.style.cssText || "";
              return {
                textwrap: element.style.whiteSpace || "nowrap",
              };
            },
          },
        },
      },
    ];
  },

  // @ts-ignore
  addCommands() {
    return {
      setTextwrap: (textwrap: string) => ({ chain }: Editor) => {
        return chain().setMark("textStyle", { textwrap }).run();
      },
    };
  },
});
