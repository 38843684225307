import { useState } from "react";
import { CommentType } from "../../../../../types";
import "./style.scss";
import { Avatar } from "../../../../Avatar";
import { TipTapEditorWithCommentBox } from "../../../../TipTapEditorWithCommentBox";
import { ToolButton, ToolButtonEnum } from "../../../../ToolButton";
import { del, edit, moreIcon } from "../../../../icons";
import { Popover } from "../../../../Popover";
import { useIsMobile } from "../../../../App";
import { t } from "../../../../../i18n";

interface ChatDrawerProps {
  commentData: CommentType[];
  deleteComment: (id: string) => void;
  editCmt: (id: string) => void;
  editCommentDetail: { html: string; text: string };
  setEditCommentDetail: (data: { html: string; text: string }) => void;
  createComment: (comment: string) => void;
  setCommentDetail: (data: { html: string; text: string }) => void;
  commentDetail: { html: string; text: string };
}

export const ChatDrawer = ({
  commentData,
  deleteComment,
  editCmt,
  editCommentDetail,
  setEditCommentDetail,
  createComment,
  setCommentDetail,
  commentDetail,
}: ChatDrawerProps) => {
  const [showOption, setShowOption] = useState<string>("");
  const [isEditComment, setIsEditComment] = useState<string>("");

  const isMobileView = useIsMobile();

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <div
      className="comment-drawer"
      style={{
        width: isMobileView ? "100%" : "30%",
      }}
    >
      <h5 className="text-secondary font-bold px-2 py-3 comment-title">
        {t("previewDialog.comments")}
      </h5>

      <div className="d-flex flex-column justify-content-between comment-section">
        <div className="comment-list h-100">
          {commentData.length
            ? commentData.map((data) => {
                const date =
                  data.createdAt && new Date(data.createdAt).toDateString();

                return (
                  <>
                    <div className="comments">
                      <div className="d-flex m-3">
                        <Avatar color={"#4c6ef5"} border={"#4c6ef5"}>
                          {data.image && data.image !== "null" ? (
                            <img src={data.image} alt="" className="Avatar" />
                          ) : data.name ? (
                            data.name ? (
                              data.name.substring(0, 2)
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Avatar>

                        <div
                          className="ms-2 flex-grow-1"
                          style={{ width: "90%" }}
                        >
                          <div className="mb-0 d-flex w-100 justify-content-between comment-wrapper position-relative">
                            <div className="d-flex flex-column w-100">
                              <p className="mb-0"> {data.name}</p>
                              <div className="comment_time">{date}</div>
                            </div>
                            {data.userEmail === user?.mail && (
                              <>
                                <div
                                  className="ms-auto cursor-pointer"
                                  style={{ rotate: "90deg" }}
                                  onClick={() =>
                                    showOption === data.id
                                      ? setShowOption("")
                                      : setShowOption(data.id ?? "")
                                  }
                                >
                                  {moreIcon}
                                </div>
                                {showOption === data.id && (
                                  <Popover>
                                    <p
                                      className="mx-3 my-2 cursor-pointer d-flex gap-2"
                                      style={{
                                        color: "#d33228",
                                        fontWeight: 600,
                                      }}
                                      onClick={() => {
                                        setShowOption("");
                                        deleteComment(data.id ? data.id : "");
                                      }}
                                    >
                                      <span
                                        style={{
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        {del}
                                      </span>
                                      {t("previewDialog.delete")}
                                    </p>
                                    <p
                                      className="mx-3 my-2 cursor-pointer d-flex gap-2"
                                      style={{
                                        fontWeight: 600,
                                      }}
                                      onClick={() => {
                                        setShowOption("");
                                        setIsEditComment(
                                          data.id ? data.id : "",
                                        );
                                        setEditCommentDetail({
                                          html: data.comment
                                            ? data.comment
                                            : "",
                                          text: "",
                                        });
                                      }}
                                    >
                                      <span
                                        style={{
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        {edit}
                                      </span>
                                      {t("previewDialog.edit")}
                                    </p>
                                  </Popover>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {isEditComment === data.id ? (
                        <div className="my-3 mx-2 flex-grow-1">
                          <TipTapEditorWithCommentBox
                            createComment={editCmt}
                            setComment={setEditCommentDetail}
                            comment={editCommentDetail.html}
                            textCommentLength={editCommentDetail.text.length}
                          />
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <ToolButton
                              className="bg-brand text-white pr-2 infoBtn mt-2 px-2 ms-0"
                              key=""
                              type={ToolButtonEnum.BUTTON}
                              title="comment"
                              aria-label="comment"
                              style={{
                                cursor:
                                  editCommentDetail.text.length > 400 &&
                                  "not-allowed",
                                width: "20%",
                              }}
                            >
                              <span
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                  editCmt(data.id as string);
                                  setIsEditComment("");
                                }}
                              >
                                {t("previewDialog.submit")}
                              </span>
                            </ToolButton>
                            <ToolButton
                              className="bg-brand text-white pr-2 infoBtn mt-2 px-2"
                              key=""
                              type={ToolButtonEnum.BUTTON}
                              title="comment"
                              aria-label="comment"
                              style={{
                                width: "20%",
                              }}
                            >
                              <span
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                  setIsEditComment("");
                                }}
                              >
                                {t("previewDialog.cancel")}
                              </span>
                            </ToolButton>
                          </div>
                        </div>
                      ) : (
                        <p
                          className="py-1 mx-3 mb-0 comment_text"
                          dangerouslySetInnerHTML={{ __html: data.comment }}
                        />
                      )}
                    </div>
                  </>
                );
              })
            : ""}
        </div>
        <div className="m-1 align-items-end">
          <TipTapEditorWithCommentBox
            createComment={createComment}
            setComment={setCommentDetail}
            comment={commentDetail.html}
            textCommentLength={commentDetail.text.length}
          />
          <div
            style={{
              flexDirection: "column",
              display: "flex",
            }}
            className="pb-3"
          >
            <ToolButton
              className="bg-brand text-white pr-2 infoBtn mt-2 px-2 ms-0"
              key=""
              type={ToolButtonEnum.BUTTON}
              title="comment"
              aria-label="comment"
              style={{
                cursor: commentDetail.text.length > 400 && "not-allowed",
                width: "15%",
              }}
            >
              <span
                style={{ fontSize: 13 }}
                onClick={() => {
                  isEditComment && setIsEditComment("");
                  createComment(commentDetail.html);
                }}
              >
                {t("previewDialog.submit")}
              </span>
            </ToolButton>
          </div>
        </div>
      </div>
    </div>
  );
};
