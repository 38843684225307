import { ROUNDNESS } from "../constants";
import { ElementOrToolType } from "../types";
import {
  ExcalidrawElement,
  ExcalidrawTextElement,
  ExcalidrawLinearElement,
  ExcalidrawBindableElement,
  ExcalidrawGenericElement,
  ExcalidrawFreeDrawElement,
  InitializedExcalidrawImageElement,
  ExcalidrawImageElement,
  InitializedExcalidrawVideoElement,
  ExcalidrawVideoElement,
  InitializedExcalidrawAudioElement,
  ExcalidrawAudioElement,
  InitializedExcalidrawFormulaElement,
  ExcalidrawFormulaElement,
  InitializedExcalidrawTextWithStyleElement,
  ExcalidrawTextWithStyleElement,
  ExcalidrawEmbeddableElement,
  ExcalidrawIframeElement,
  ExcalidrawIframeLikeElement,
  RoundnessType,
  ExcalidrawTextElementWithContainer,
  ExcalidrawFrameLikeElement,
  ExcalidrawFrameElement,
  ExcalidrawMagicFrameElement,
  ExcalidrawTextContainer,
  ExcalidrawMermaidDiagramElement,
  InitializedExcalidrawMermaidDiagramElement,
  ExcalidrawElbowArrowElement,
} from "./types";

export const isGenericElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawGenericElement => {
  return (
    element != null &&
    (element.type === "selection" ||
      element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse")
  );
};

export const isTextElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawTextElement => {
  return element != null && element.type === "text";
};

export const isFrameElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawFrameElement => {
  return element != null && element.type === "frame";
};

export const isTextWithStylesElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawTextWithStyleElement => {
  return (
    element != null && element.type === "textWithStyles" && !!element.fileId
  );
};

export const isMermaidDiagramElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawMermaidDiagramElement => {
  return (
    element != null && element.type === "mermaidDiagram" && !!element.fileId
  );
};

export const isInitializedImageElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawImageElement => {
  return !!element && element.type === "image" && !!element.fileId;
};

export const isInitializedVideoElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawVideoElement => {
  return !!element && element.type === "video" && !!element.fileId;
};
export const isInitializedAudioElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawAudioElement => {
  return !!element && element.type === "audio" && !!element.fileId;
};

export const isInitializedFormulaElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawFormulaElement => {
  return !!element && element.type === "formula" && !!element.fileId;
};

export const isInitializedTextWithStylesElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawTextWithStyleElement => {
  return !!element && element.type === "textWithStyles" && !!element.fileId;
};
export const isInitializedMermaidDiagramElement = (
  element: ExcalidrawElement | null,
): element is InitializedExcalidrawMermaidDiagramElement => {
  return !!element && element.type === "mermaidDiagram" && !!element.fileId;
};

export const isFreeDrawElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawFreeDrawElement => {
  return element != null && isFreeDrawElementType(element.type);
};

export const isFreeDrawElementType = (
  elementType: ExcalidrawElement["type"],
): boolean => {
  return elementType === "freedraw";
};

export const isLinearElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawLinearElement => {
  return element != null && isLinearElementType(element.type);
};

export const isLinearElementType = (
  elementType: ExcalidrawElement["type"],
): boolean => {
  return (
    elementType === "arrow" || elementType === "line" // || elementType === "freedraw"
  );
};

export const isBindingElement = (
  element?: ExcalidrawElement | null,
  includeLocked = true,
): element is ExcalidrawLinearElement => {
  return (
    element != null &&
    (element.locked !== true || includeLocked === true) &&
    isBindingElementType(element.type)
  );
};

export const isBindingElementType = (
  elementType: ElementOrToolType,
): boolean => {
  return elementType === "arrow";
};

export const isBindableElement = (
  element: ExcalidrawElement | null,
  includeLocked = true,
): element is ExcalidrawBindableElement => {
  return (
    element != null &&
    (element.locked !== true || includeLocked === true) &&
    (element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse" ||
      element.type === "text" ||
      element.type === "textWithStyles")
  );
};

export const isExcalidrawElement = (element: any): boolean => {
  return (
    element?.type === "text" ||
    element?.type === "textWithStyles" ||
    element?.type === "diamond" ||
    element?.type === "rectangle" ||
    element?.type === "ellipse" ||
    element?.type === "arrow" ||
    element?.type === "freedraw" ||
    element?.type === "line" ||
    element?.type === "formula" ||
    element?.type === "mermaidDiagram"
  );
};

export const hasBoundTextElement = (
  element: ExcalidrawElement | null,
): element is MarkNonNullable<ExcalidrawBindableElement, "boundElements"> => {
  return (
    isTextBindableContainer(element) &&
    !!element.boundElements?.some(({ type }) => type === "text")
  );
};

export const isImageElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawImageElement => {
  return !!element && element.type === "image";
};
export const isVideoElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawVideoElement => {
  return !!element && element.type === "video";
};
export const isAudioElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawAudioElement => {
  return !!element && element.type === "audio";
};

export const isFormulaElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawFormulaElement => {
  return !!element && element.type === "formula";
};

export const isArrowElement = (
  element?: ExcalidrawElement | null,
): element is ExcalidrawLinearElement => {
  return element != null && element.type === "arrow";
};

export const isEmbeddableElement = (
  element: ExcalidrawElement | null | undefined,
): element is ExcalidrawEmbeddableElement => {
  return !!element && element.type === "embeddable";
};

export const isIframeElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawIframeElement => {
  return !!element && element.type === "iframe";
};

export const isIframeLikeElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawIframeLikeElement => {
  return (
    !!element && (element.type === "iframe" || element.type === "embeddable")
  );
};

export const isUsingProportionalRadius = (type: string) =>
  type === "line" || type === "arrow" || type === "diamond";

export const isUsingAdaptiveRadius = (type: string) =>
  type === "rectangle" ||
  type === "embeddable" ||
  type === "iframe" ||
  type === "image";

export const getDefaultRoundnessTypeForElement = (
  element: ExcalidrawElement,
) => {
  if (isUsingProportionalRadius(element.type)) {
    return {
      type: ROUNDNESS.PROPORTIONAL_RADIUS,
    };
  }

  if (isUsingAdaptiveRadius(element.type)) {
    return {
      type: ROUNDNESS.ADAPTIVE_RADIUS,
    };
  }

  return null;
};

export const canApplyRoundnessTypeToElement = (
  roundnessType: RoundnessType,
  element: ExcalidrawElement,
) => {
  if (
    (roundnessType === ROUNDNESS.ADAPTIVE_RADIUS ||
      // if legacy roundness, it can be applied to elements that currently
      // use adaptive radius
      roundnessType === ROUNDNESS.LEGACY) &&
    isUsingAdaptiveRadius(element.type)
  ) {
    return true;
  }
  if (
    roundnessType === ROUNDNESS.PROPORTIONAL_RADIUS &&
    isUsingProportionalRadius(element.type)
  ) {
    return true;
  }

  return false;
};

export const isBoundToContainer = (
  element: ExcalidrawElement | null,
): element is ExcalidrawTextElementWithContainer => {
  return (
    element !== null &&
    "containerId" in element &&
    element.containerId !== null &&
    isTextElement(element)
  );
};

export const isFrameLikeElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawFrameLikeElement => {
  return (
    element != null &&
    (element.type === "frame" || element.type === "magicframe")
  );
};

export const getFrameLikeTitle = (
  element: ExcalidrawFrameLikeElement,
  frameIdx: number,
) => {
  // TODO name frames AI only is specific to AI frames
  return element.name === null
    ? isFrameElement(element)
      ? `Frame ${frameIdx}`
      : `AI Frame $${frameIdx}`
    : element.name;
};

export const isMagicFrameElement = (
  element: ExcalidrawElement | null,
): element is ExcalidrawMagicFrameElement => {
  return element != null && element.type === "magicframe";
};

// export const isElbowArrow = (
//   element?: ExcalidrawElement,
// ): element is ExcalidrawElbowArrowElement => {
//   return isArrowElement(element) && element.elbowed;
// };

export const isTextBindableContainer = (
  element: ExcalidrawElement | null,
  includeLocked = true,
): element is ExcalidrawTextContainer => {
  return (
    element != null &&
    (element.locked !== true || includeLocked === true) &&
    (element.type === "rectangle" ||
      element.type === "diamond" ||
      element.type === "ellipse" ||
      isArrowElement(element))
  );
};
