import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "../components/LoginPage";
import Whiteboard from "../components/Whiteboard";
// import ClosePopup from "../msal-auth/components/ClosePopup";
// import ConsentPopup from "../msal-auth/components/ConsentPopup";
import Loader from "../shareComponent/Loader";
import { loginRequest } from "../msal-auth/msalAuth";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { callMsGraph } from "../msal-auth/graph";
import { saveUsernameToLocalStorage } from "../../excalidraw-app/data/localStorage";
import io from "socket.io-client";
import {
  checkUserRole,
  getWhiteboardStudent,
  postWhiteboardStudent,
} from "../../excalidraw-app/api/userAPI";
import { useInRouterContext } from "react-router";
import { UserProfileContext } from "../../components/contexts/UserProfile.context";
import { StudentMessageContext } from "../../components/contexts/StudentMessage.context";
import {
  studentAccessRevokeMessage,
  studentSessionEndMessage,
  studentUnAuthorizedMessage,
} from "../../excalidraw-app/api/constant";
require("dotenv").config();

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [flag, setFlag] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [studentMessage, setStudentMessage] = useState(null);

  const route = useContext(useInRouterContext);

  const callGraphFromClient = async (acctoken) => {
    let graphPhotoEndpoint = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    let graphRequestParams = {
      method: "GET",
      headers: {
        "Content-Type": "image/jpg",
        Authorization: `Bearer ${acctoken}`,
      },
    };

    let response = await fetch(graphPhotoEndpoint, graphRequestParams).catch(
      (err) => {
        console.error("Unhandled fetch error: ", err);
      },
    );
    if (!response?.ok) {
      console.error("ERROR: ", response);
      window.localStorage.removeItem("profile-blob");
    } else {
      let imageBlog = await response.blob().catch((err) => {
        console.error("Unhandled fetch error: ", err);
      });
      var reader = new FileReader();
      reader.readAsDataURL(imageBlog);
      reader.onloadend = function () {
        var base64data = reader.result;
        window.localStorage.setItem("profileBase64", base64data);
      };
      window.localStorage.setItem(
        "profile-blob",
        URL.createObjectURL(imageBlog),
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const userLS = JSON.parse(localStorage.getItem("user"));
      const accessToken = localStorage.getItem("access_token");
      if (userLS && userLS !== "undefined") {
        setUser(userLS);
        if (userLS?.accessToken) {
          setAccessToken(userLS?.accessToken);
        } else {
          setAccessToken(accessToken);
        }
      } else {
        window.parent.postMessage(
          {
            source: "whiteboard",
            message: "Resent the data!",
          },
          // process.env.REACT_APP_ACV_PARENT_APP,
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      }
    }, 1000);

    setTimeout(() => {
      setFlag(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (accessToken) {
      callGraphFromClient(accessToken);
    }
  }, [accessToken]);

  const handleLogin = () => {
    if (window.parent !== window) {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getUserdata();
    }
  }, [instance, accounts, inProgress]);

  const getUserdata = () => {
    if (accounts && accounts.length) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          callGraphFromClient(response.accessToken);
          callMsGraph(response.accessToken).then((res) => {
            setUser(res);
            setAccessToken(response.accessToken);
            window.localStorage.setItem("access_token", response.accessToken);
            window.localStorage.setItem("user", JSON.stringify(res));
          });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then((response) => {
                callGraphFromClient(response.accessToken);
                callMsGraph(response.accessToken).then((res) => {
                  setUser(res);
                  setAccessToken(response.accessToken);
                  window.localStorage.setItem(
                    "access_token",
                    response.accessToken,
                  );
                  window.localStorage.setItem("user", JSON.stringify(res));
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    }
  };

  useEffect(async () => {
    const slug = new URLSearchParams(window.location.search).get("slug");

    checkUserRole(slug).then((res) => {
      if (res?.image) {
        setUserProfile(res?.image);
      }
      localStorage.setItem("Role", res?.role);
      if (["SuperAdmin", "Admin", "Teacher"].includes(res?.actualRole)) {
        window.localStorage.setItem("S", false);
      } else {
        window.localStorage.setItem("S", true);
      }
    });

    if (accessToken && user.role) {
      const lessonId = new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "");

      localStorage.setItem("Role", user.role);
      if (["SuperAdmin", "Admin", "Teacher"].includes(user.role)) {
        window.localStorage.setItem("S", false);
      } else {
        getWhiteboardStudent(lessonId).then((res) => {
          const data = res.result?.data;
          const studData = data?.find((stud) => {
            return stud.studEmail == user.mail;
          });

          if (data && data.length && studData) {
            window.localStorage.setItem("S", true);
            saveUsernameToLocalStorage(user?.displayName || "");
            if (
              typeof studData === "object" &&
              studData?.isWhiteboard === false
            ) {
              console.log("isWhiteboard", studData?.isWhiteboard);
              setStudentMessage({ ...studentAccessRevokeMessage });
              const isModalOpen = document
                .getElementsByClassName("modal fade Karla")[0]
                ?.classList.contains("show");

              if (!isModalOpen) {
                if (document.getElementsByClassName("acv")) {
                  if (document.getElementsByClassName("acv")[0]) {
                    document.getElementsByClassName("acv")[0].style.opacity =
                      "0";
                  }
                }
                document.getElementById("errModalBtn")?.click();
              } else {
                if (document.getElementsByClassName("acv")) {
                  document.getElementsByClassName("acv")[0].style.opacity = "1";
                }
              }
            } else if (
              typeof studData === "object" &&
              studData?.isWhiteboard === 2
            ) {
              window.parent.postMessage(
                {
                  type: "IS_START_COLLABORATION",
                  session: false,
                  lessonId: lessonId,
                },
                `${process.env.REACT_APP_PARENT_APP}`,
              );
              setStudentMessage({ ...studentSessionEndMessage });

              // const isModalOpen = document
              //   .getElementsByClassName("modal fade Karla")[0]
              //   ?.classList?.contains("show");

              // if (!isModalOpen) {
              //   if (document.getElementsByClassName("acv")[0])
              //     document.getElementsByClassName("acv")[0].style.opacity = "0";
              //   document.getElementById("errModalBtn")?.click();
              // } else {
              //   if (document.getElementsByClassName("acv")[0])
              //     document.getElementsByClassName("acv")[0].style.opacity = "1";
              // }
            } else if (
              typeof studData === "object" &&
              studData?.isWhiteboard === true
            ) {
              const isModalOpen = document
                .getElementsByClassName("modal fade Karla")[0]
                ?.classList?.contains("show");
              if (document.getElementsByClassName("acv")[0]) {
                document.getElementsByClassName("acv")[0].style.opacity = "1";
              }

              if (isModalOpen) {
                document.getElementById("errModalBtn")?.click();
              }
            }
          } else {
            //check student add template into their collection
            //if yes then they have access to import that.
            const isSetACVLibrary = Boolean(
              localStorage.getItem("SET-ACV-LIBRARY"),
            );
            if (!isSetACVLibrary) {
              setStudentMessage({ ...studentUnAuthorizedMessage });

              // document.getElementById("errModalBtn")?.click();
            }
            // document.getElementsByClassName("acv")[0].style.opacity =
            //   "0";
          }
        });
      }
    }
  }, [accessToken]);
  return (
    <Router>
      <>
        {flag ? (
          user || accessToken ? (
            user && accessToken ? (
              <UserProfileContext.Provider
                value={{
                  image: userProfile,
                }}
              >
                <StudentMessageContext.Provider
                  value={{
                    studentMessage,
                    setStudentMessage,
                  }}
                >
                  <Whiteboard />
                </StudentMessageContext.Provider>
              </UserProfileContext.Provider>
            ) : (
              <Loader />
            )
          ) : (
            <>
              <AuthenticatedTemplate>
                <Loader />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                {/* <Route exact path="/auth-start" component={ConsentPopup} />
                <Route exact path="/auth-end" component={ClosePopup} /> */}
                <Route exact path="/">
                  <LoginPage login={() => handleLogin()} />
                </Route>
              </UnauthenticatedTemplate>
            </>
          )
        ) : (
          <Loader />
        )}
      </>
    </Router>
  );
};
export default App;
