import React, {
  KeyboardEvent,
  ReactNode,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { t } from "../i18n";
import { Dialog } from "./Dialog";

import { ToolButton, ToolButtonEnum } from "./ToolButton";

import {
  EXPORT_DATA_TYPES,
  EXPORT_SOURCE,
  MIME_TYPES,
  VERSIONS,
} from "../constants";
import { ExportedLibraryData } from "../data/types";
import { exportToCanvas } from "../packages/utils";
import { AppState, BinaryFiles, LibraryItem, LibraryItems } from "../types";

import { canvasToBlob, resizeImageFile } from "../data/blob";
import { chunk } from "../utils";
import "./PublishLibrary.scss";
import SingleLibraryItem from "./SingleLibraryItem";
import { UserProfileContext } from "./contexts/UserProfile.context";
import { BlobServiceClient } from "@azure/storage-blob";
import { exportToSvg } from "../scene/export";
import oc from "open-color";
import { urlToDataUrl } from "../utils/convertToDataURL";
import { async } from "q";
import {
  uploadImageToAzureBlob,
  uploadSVGToAzureBlob,
} from "../utils/uploadImageToAzureBlob";
import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { updateWhiteboardMedia } from "../excalidraw-app/api/loadDataFromCosmosDB";
import { LibraryType } from "./TemplateInfo";
import { FileId } from "../element/types";

const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net?${process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN}`,
);

interface PublishLibraryDataParams {
  authorName: string;
  githubHandle: string;
  name: string;
  description: string;
  twitterHandle: string;
  website: string;
  title: string;
}

const LOCAL_STORAGE_KEY_PUBLISH_LIBRARY = "publish-library-data";

const savePublishLibDataToStorage = (data: PublishLibraryDataParams) => {
  try {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_PUBLISH_LIBRARY,
      JSON.stringify(data),
    );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

const importPublishLibDataFromStorage = () => {
  try {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY_PUBLISH_LIBRARY);
    if (data) {
      return JSON.parse(data);
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

const uploadFilesToBlobStorage = async (files: BinaryFiles[]) => {
  try {
    if (files?.length > 0) {
      // const uploadFiles = [];
      // Container and blob information
      const containerName = "published-files";
      const lessonId = await getLessonId();
      const uploadFiles = await Promise.all(
        files.map(async (file: any) => {
          const type = file.mimeType.split("/");
          const updateMedia = await updateWhiteboardMedia({
            type: type[0],
            fileId: file.id,
            lessonId: lessonId,
            data: {
              ...file,
              isPublished: true,
            },
          });
          const blobName = `${file.id}.${type[1]}-${Date.now()}`; // Name for the uploaded blob
          // Your Data URL
          const dataURL = file.dataURL; // Replace with your Data URL
          // Convert the Data URL to binary data
          let base64 = dataURL;
          const result = checkType(dataURL);
          if (result === "URL") {
            await urlToDataUrl(file.dataURL, file.mimeType).then(
              async (result) => {
                if (result) {
                  base64 = result.dataUrl;
                } else {
                  console.log(
                    "Failed to fetch and convert the URL to a data URL.",
                  );
                }
              },
            );
          }
          const data = base64.split(",")[1];

          const buffer = Buffer.from(data, "base64");

          // Get a reference to the container
          const containerClient = blobServiceClient.getContainerClient(
            containerName,
          );

          // Create a new blob client
          const blobClient = containerClient.getBlockBlobClient(blobName);

          // Upload the binary data to the blob
          await blobClient.uploadData(buffer, {
            blobHTTPHeaders: {
              blobContentType: file.mimeType,
            },
          });
          return {
            id: file.id,
            mimeType: file.mimeType,
            url: `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net/${containerName}/${blobName}`,
            created: file.created,
            isPublished: true,
          };
        }),
      );

      return uploadFiles;
    }
  } catch (error) {
    console.log("ERROR", error);
  }
};

const isBase64 = (str: string) => {
  try {
    // Attempt to decode the string as base64
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

const isURL = (str: string) => {
  try {
    // Attempt to create a URL object
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
};

const checkType = (str: string) => {
  if (str.startsWith("http://") || str.startsWith("https://")) {
    return isURL(str) ? "URL" : "Not a valid URL";
  } else if (isBase64(str)) {
    return "Base64";
  } else {
    return "Unknown";
  }
};

const PublishLibrary = ({
  onClose,
  libraryItems,
  appState,
  onSuccess,
  onError,
  updateItemsInStorage,
  onRemove,
  files,
  isTrusted,
}: {
  onClose: () => void;
  libraryItems: LibraryItems;
  appState: AppState;
  onSuccess?: (data: {
    url: string;
    authorName: string;
    items: LibraryItems | LibraryType[];
  }) => void;
  onError: (error: Error) => void;
  updateItemsInStorage?: (items: LibraryItems) => void;
  onRemove?: (id: string) => void;
  files: BinaryFiles;
  isTrusted: number;
}) => {
  const [clonedLibItems, setClonedLibItems] = useState<LibraryItems>(
    libraryItems.slice(),
  );

  const [uploadedFiles, setUploadedFiles] = useState<BinaryFiles[]>([]);
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleChange = () => {
    setIsToggled(!isToggled);
  };

  const [svgRefs] = useState(() =>
    Array.from({ length: clonedLibItems.length }, () =>
      createRef<HTMLDivElement>(),
    ),
  );

  const [libraryData, setLibraryData] = useState<PublishLibraryDataParams>({
    authorName: "",
    githubHandle: "",
    name: "",
    description: "",
    twitterHandle: "",
    website: "",
    title: "",
  });
  const { image } = useContext(UserProfileContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const [tags, setTags] = useState<string[]>([]);
  const [tag, setTag] = useState("");
  const addTags = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === "Enter" && tag) {
      setTags([...tags, tag]);
      setTag("");
    }
  };

  const removeTags = (index: number) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  useEffect(() => {
    if (appState.editingLibrary.isEditing) {
      const libData = appState.editingLibrary.libraryInfo;
      if (!libData) return;

      setLibraryData({
        ...libraryData,
        name: libData?.name,
        description: libData?.description,
        title: libData?.title || "",
      });
      setTags(libData.tags);
    }
  }, [appState.editingLibrary]);

  useEffect(() => {
    const data = importPublishLibDataFromStorage();
    if (data) {
      setLibraryData(data);
    }
  }, []);

  useEffect(() => {
    setClonedLibItems(libraryItems.slice());
    if (files && libraryItems && libraryItems.length > 0) {
      const allIds = libraryItems.flatMap((fileData) =>
        // @ts-ignore
        fileData?.elements?.map((element) => element?.fileId),
      );
      const matchingObjects = Object.keys(files)
        .filter((id) => allIds.includes(id))
        .map((id) => files[id]);
      setUploadedFiles([...matchingObjects] as any);
    }
  }, [libraryItems]);

  const onInputChange = (event: any) => {
    setLibraryData({
      ...libraryData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const erroredLibItems: LibraryItem[] = [];
    let isError = false;

    clonedLibItems.forEach((libItem) => {
      let error = "";
      if (!libItem.name || !libraryData.description || !libraryData.name) {
        error = t("publishDialog.errors.required");
        isError = true;
      }
      if (!libItem.name) erroredLibItems.push({ ...libItem, error });
      else erroredLibItems.push({ ...libItem, error: "" });

      if (!libraryData.description || !libraryData.name) {
        setErrors({
          ...errors,
          description: !libraryData.description ? error : "",
          name: !libraryData.name ? error : "",
        });
      } else setErrors({ ...errors, description: "", name: "" });
    });

    if (isError) {
      setClonedLibItems(erroredLibItems);
      setIsSubmitting(false);
      return;
    }

    const promises = clonedLibItems.map(async (libItem, index) => {
      const svg = await exportToSvg(
        libItem.elements,
        {
          exportBackground: false,
          viewBackgroundColor: oc.white,
        },
        files,
      );

      const url = await uploadSVGToAzureBlob(svg, "collecations");
      return url || "";
    });

    let previewImg: string[] = [];
    await Promise.all(promises)
      .then(async (images) => {
        if (!images.length) return;
        previewImg = images;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const libContent: ExportedLibraryData = {
      type: EXPORT_DATA_TYPES.excalidrawLibrary,
      version: VERSIONS.excalidrawLibrary,
      source: EXPORT_SOURCE,
      libraryItems: clonedLibItems,
    };

    const content = JSON.stringify(libContent, null, 2);
    const lib = new Blob([content], { type: "application/json" });
    const lessonId = new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "");

    const user: any = JSON.parse(localStorage.getItem("user")!);

    const uploadFile = await uploadFilesToBlobStorage(uploadedFiles);

    const formData = new FormData();
    const slug = new URLSearchParams(window.location.search).get("slug");

    // return;
    formData.append("excalidrawLib", lib);
    formData.append("previewImage", JSON.stringify(previewImg));
    formData.append("previewImageType", "image/png");
    formData.append("title", libraryData.title);
    formData.append("authorName", user?.displayName);
    // formData.append("githubHandle", libraryData.githubHandle);
    formData.append("name", libraryData.name);
    formData.append("description", libraryData.description);
    // formData.append("twitterHandle", libraryData.twitterHandle);
    formData.append("website", libraryData.website);
    formData.append("lessonId", lessonId as string);
    formData.append("userId", user.mail);
    formData.append("slug", slug || "");
    formData.append("isPublic", isToggled.toString());
    formData.append("tag", JSON.stringify(tags));
    formData.append("profileImg", image ? image : "");
    formData.append("files", JSON.stringify(uploadFile));
    if (
      appState?.editingLibrary?.isEditing &&
      appState?.editingLibrary?.libraryInfo.id
    ) {
      formData.append("id", appState?.editingLibrary?.libraryInfo.id);
    }

    fetch(`${process.env.REACT_APP_LIBRARY_BACKEND}/submit`, {
      // fetch(`http://localhost:8000/submit`, {
      method: "post",
      body: formData,
    })
      .then(
        (response) => {
          if (response.ok) {
            return response.json().then(({ url }) => {
              // flush data from local storage1
              localStorage.removeItem(LOCAL_STORAGE_KEY_PUBLISH_LIBRARY);
              onSuccess &&
                onSuccess({
                  url,
                  authorName: libraryData.authorName,
                  items: clonedLibItems,
                });
            });
          }
          return response
            .json()
            .catch(() => {
              throw new Error(response.statusText || "something went wrong");
            })
            .then((error) => {
              throw new Error(
                error.message || response.statusText || "something went wrong",
              );
            });
        },
        (err) => {
          console.error(err);
          onError(err);
          setIsSubmitting(false);
        },
      )
      .catch((err) => {
        console.error(err);
        onError(err);
        setIsSubmitting(false);
      });
  };

  const updateLibraryItem = async (createNew: boolean) => {
    const erroredLibItems: LibraryItem[] = [];
    let isError = false;
    clonedLibItems.forEach((libItem) => {
      let error = "";
      if (!libItem.name || !libraryData.description || !libraryData.name) {
        error = t("publishDialog.errors.required");
        isError = true;
      }
      if (!libItem.name) erroredLibItems.push({ ...libItem, error });
      else erroredLibItems.push({ ...libItem, error: "" });

      if (!libraryData.description || !libraryData.name) {
        setErrors({
          ...errors,
          description: !libraryData.description ? error : "",
          name: !libraryData.name ? error : "",
        });
      } else setErrors({ ...errors, description: "", name: "" });
    });
    if (isError) {
      setClonedLibItems(erroredLibItems);
      setIsSubmitting(false);
      return;
    }
    const promises = clonedLibItems.map(async (libItem, index) => {
      const svg = await exportToSvg(
        libItem.elements,
        {
          exportBackground: false,
          viewBackgroundColor: oc.white,
        },
        files,
      );
      // Serialize the SVG element to a string

      const url = await uploadSVGToAzureBlob(svg, "collecations");
      return url || "";
    });

    let previewImg: string[] = [];
    await Promise.all(promises)
      .then(async (images) => {
        if (!images.length) return;
        previewImg = images;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const libContent: ExportedLibraryData = {
      type: EXPORT_DATA_TYPES.excalidrawLibrary,
      version: VERSIONS.excalidrawLibrary,
      source: EXPORT_SOURCE,
      libraryItems: clonedLibItems,
    };

    const content = JSON.stringify(libContent, null, 2);
    const lib = new Blob([content], { type: "application/json" });
    const lessonId = new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "");

    const user: any = JSON.parse(localStorage.getItem("user")!);

    const uploadFile = await uploadFilesToBlobStorage(uploadedFiles);
    const slug = new URLSearchParams(window.location.search).get("slug");

    const formData = new FormData();

    const libData = appState.editingLibrary;
    if (!libData.libraryInfo) return;

    formData.append("excalidrawLib", lib);
    formData.append("previewImage", JSON.stringify(previewImg));
    formData.append("previewImageType", "image/png");
    formData.append("title", libraryData.title);
    formData.append("authorName", user?.displayName);
    formData.append("name", libraryData.name);
    formData.append("description", libraryData.description);
    formData.append("lessonId", lessonId as string);
    formData.append("userId", user.mail);
    formData.append("tag", JSON.stringify(libData.libraryInfo.tags));
    formData.append("profileImg", image ? image : "");
    formData.append("files", JSON.stringify(uploadFile));
    formData.append("id", libData.libraryInfo.id);
    formData.append("createNew", String(createNew));
    formData.append("slug", slug || "");

    fetch(`${process.env.REACT_APP_ACV_BACKEND_API}/api/library/collection`, {
      method: "put",
      body: formData,
    })
      .then(
        (response) => {
          if (response.ok) {
            return response.json().then(({ url }) => {
              // flush data from local storage1
              localStorage.removeItem(LOCAL_STORAGE_KEY_PUBLISH_LIBRARY);
              onSuccess &&
                onSuccess({
                  url,
                  authorName: libraryData.authorName,
                  items: clonedLibItems,
                });
            });
          }
          return response
            .json()
            .catch(() => {
              throw new Error(response.statusText || "something went wrong");
            })
            .then((error) => {
              throw new Error(
                error.message || response.statusText || "something went wrong",
              );
            });
        },
        (err) => {
          console.error(err);
          onError(err);
          setIsSubmitting(false);
        },
      )
      .catch((err) => {
        console.error(err);
        onError(err);
        setIsSubmitting(false);
      });
  };

  const renderLibraryItems = () => {
    if (!svgRefs) return;
    const items: ReactNode[] = [];

    clonedLibItems.forEach((libItem, index) => {
      items.push(
        <div className="single-library-item-wrapper w-100" key={index}>
          <SingleLibraryItem
            libItem={libItem}
            appState={appState}
            index={index}
            onChange={(val, index) => {
              const items = clonedLibItems.slice();
              items[index].name = val;
              setClonedLibItems(items);
              setLibraryData({ ...libraryData, title: val });
            }}
            onRemove={onRemove && onRemove}
            files={files}
            svgRef={svgRefs[index]}
          />
        </div>,
      );
    });
    return <div className="selected-library-items">{items}</div>;
  };

  const onDialogClose = useCallback(() => {
    updateItemsInStorage && updateItemsInStorage(clonedLibItems);
    savePublishLibDataToStorage(libraryData);
    onClose();
  }, [clonedLibItems, onClose, updateItemsInStorage, libraryData]);

  const shouldRenderForm = !!libraryItems.length;

  const containsPublishedItems = appState.editingLibrary.isEditing;

  // const containsPublishedItems = libraryItems.some(
  //   (item) => item.status === "published",
  // );

  return (
    <Dialog
      onCloseRequest={onDialogClose}
      title={t("publishDialog.title")}
      className="publish-library"
    >
      {shouldRenderForm ? (
        // <form>
        <>
          <div style={{ overflowY: "auto" }}>
            <div className="publish-library-note">
              {t("publishDialog.noteItems")}
            </div>
            {isTrusted ? (
              <div
                className="form-check form-switch"
                style={{ display: "flex", gap: "1%", marginLeft: "16px" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={isToggled}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Share with Everyone
                </label>
              </div>
            ) : null}
            {renderLibraryItems()}
            <div className="publish-library__fields">
              <label className="mt-2">
                <div>
                  <span>{t("publishDialog.libraryName")}</span>
                  <span aria-hidden="true" className="required">
                    *
                  </span>
                </div>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  name="name"
                  required
                  value={libraryData.name}
                  className="input"
                  onChange={onInputChange}
                  placeholder={t("publishDialog.placeholder.libraryName")}
                />
                <span className="error">{errors.name}</span>
              </div>

              <label style={{ alignItems: "flex-start" }} className="mt-2">
                <div>
                  <span>{t("publishDialog.libraryDesc")}</span>
                  <span aria-hidden="true" className="required">
                    *
                  </span>
                </div>
              </label>
              <div style={{ position: "relative" }}>
                <textarea
                  name="description"
                  rows={4}
                  required
                  value={libraryData.description}
                  onChange={onInputChange}
                  className="input"
                  placeholder={t("publishDialog.placeholder.libraryDesc")}
                />
                <span className="error">{errors.description}</span>
              </div>

              <label className="mt-2">
                <span>
                  {"Tag"}{" "}
                  <span style={{ fontSize: 13 }}>
                    {" "}
                    (Press enter to add tags)
                  </span>
                </span>
              </label>
              <div className="tags-input">
                <ul id="tags">
                  {tags.map((tag, index) => (
                    <li key={index} className="tag">
                      <span className="tag-title">{tag}</span>
                      <span
                        className="tag-close-icon"
                        onClick={() => removeTags(index)}
                      >
                        &#x2715;
                      </span>
                    </li>
                  ))}
                </ul>
                <input
                  type="text"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyUp={addTags}
                  placeholder="Press enter to add tags"
                />
              </div>

              <span
                className="publish-library-note"
                style={{ paddingBottom: 5 }}
              >
                {t("publishDialog.noteLicense.pre")}
                <a href="#" target="_blank" rel="noopener noreferrer">
                  {t("publishDialog.noteLicense.link")}
                </a>
                {t("publishDialog.noteLicense.post")}
              </span>
            </div>
            <div className="publish-library-note">
              {t("publishDialog.noteDescription.pre")}
              <a href="#" target="_blank" rel="noopener noreferrer">
                {t("publishDialog.noteDescription.link")}
              </a>{" "}
              {t("publishDialog.noteDescription.post")}
            </div>
            {/* <span className="publish-library-note">
            {t("publishDialog.noteGuidelines.pre")}
            <a href="#" target="_blank" rel="noopener noreferrer">
              {t("publishDialog.noteGuidelines.link")}
            </a>
            {t("publishDialog.noteGuidelines.post")}
          </span> */}
          </div>
          <div className="publish-library__buttons pt-3">
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={t("buttons.cancel")}
              aria-label={t("buttons.cancel")}
              label={t("buttons.cancel")}
              onClick={onDialogClose}
              data-testid="cancel-clear-canvas-button"
              className="publish-library__buttons--cancel"
            />
            {/* <ToolButton
              type="button"
              title={t("buttons.submit")}
              aria-label={t("buttons.submit")}
              // label={t("buttons.submit")}
              className="publish-library__buttons--confirm"
              showAriaLabel={true}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => onSubmit(e)}
            /> */}
            {containsPublishedItems ? (
              <>
                <ToolButton
                  type={ToolButtonEnum.BUTTON}
                  title={"Submit Update"}
                  aria-label={"Submit Update"}
                  // label={t("buttons.submit")}
                  className="publish-library__buttons--confirm"
                  showAriaLabel={true}
                  onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                    // onSubmit(e);
                    updateLibraryItem(false)
                  }
                />
                <ToolButton
                  type={ToolButtonEnum.BUTTON}
                  title={"Create New"}
                  aria-label={"Create New"}
                  // label={t("buttons.submit")}
                  className="publish-library__buttons--confirm"
                  showAriaLabel={true}
                  onClick={() => {
                    updateLibraryItem(true);
                  }}
                />
              </>
            ) : (
              <ToolButton
                type={ToolButtonEnum.BUTTON}
                title={t("buttons.submit")}
                aria-label={t("buttons.submit")}
                // label={t("buttons.submit")}
                className="publish-library__buttons--confirm "
                showAriaLabel={true}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  onSubmit(e);
                }}
              />
            )}
          </div>
        </>
      ) : (
        //  </form>
        <p style={{ padding: "1em", textAlign: "center", fontWeight: 500 }}>
          {t("publishDialog.atleastOneLibItem")}
        </p>
      )}
    </Dialog>
  );
};

export default PublishLibrary;
