import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  CollocationType,
  CustomCategies,
  LibraryItem,
} from "../../../../types";
import { Avatar } from "../../../Avatar";
import { CollectionImagePreview } from "../../../collectionImagePreview";
import {
  PlusIcon,
  bookmarkIcon,
  del,
  edit,
  fillBookmarkIcon,
  fillLikeIcon,
  importCanvas,
  likeIcon,
  preview,
  templateIcon,
  videoIconSvg,
} from "../../../icons";
import "../../style.scss";
import { MoveToCategoryPopover } from "../../tabs/collections/components/moveToCategoryPopover";
import { ExcalidrawElement, NonDeleted } from "../../../../element/types";
import { t } from "../../../../i18n";
import { ToolButton, ToolButtonEnum } from "../../../ToolButton";

interface CollectionCardsProps {
  images?: string | string[];
  userProfile?: string;
  userName?: string;
  created?: string;
  _key?: number;
  name?: string;
  type?: string;
  likes?: number;
  isLikefill?: boolean;
  isBookMarkFill?: boolean;
  onLike?: () => void;
  onBookmark?: () => void;
  setFullScreenCollectionPreview?: () => void;
  onPreview?: () => void;
  buttonText?: string;
  likeBookmarkIcon?: boolean;
  isHoverPreviewBtns?: boolean;
  isHoverUseThisTemplateBtn?: boolean;
  onUseThisTemplate?: (elemenent?: LibraryItem["elements"]) => void;
  source?: string;
  onAddtoCollectionBtnClick?: () => void;
  selectedTemplate?: string[];
  isDelete?: boolean;
  onDelete?: () => void;
  isEdit?: boolean;
  onEdit?: () => void;
  isMove?: boolean;
  id?: string;
  onAfterMovedToCategory?: (category: string[]) => void;
  elements?: LibraryItem["elements"];
  templateCategory?: string[];
  onAfterDeleteCategory?: (id: string) => void;
  isCustomCatagory?: boolean;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
  template?: CollocationType;
  categoryId?: string;
}

const CollectionCards = ({
  _key: key = 0,
  images,
  userName,
  userProfile,
  created,
  name,
  type,
  likes = 0,
  isLikefill = false,
  isBookMarkFill = false,
  onLike,
  onBookmark,
  setFullScreenCollectionPreview,
  onPreview,
  buttonText,
  likeBookmarkIcon,
  isHoverPreviewBtns,
  onUseThisTemplate,
  source,
  onAddtoCollectionBtnClick,
  selectedTemplate,
  isHoverUseThisTemplateBtn,
  isDelete,
  onDelete,
  isEdit = false,
  onEdit,
  isMove = false,
  id,
  onAfterMovedToCategory,
  elements,
  templateCategory,
  onAfterDeleteCategory,
  isCustomCatagory,
  setSelectedCatagoryForExportCanvas,
  selectedCatagoryForExportCanvas,
  template,
  categoryId,
}: CollectionCardsProps) => {
  const [openCategoryPopover, setOpenCategoryPopover] = useState(false);

  const isActivePreviewCard = () => {
    if (!selectedCatagoryForExportCanvas || !template) {
      return false;
    }
    return selectedCatagoryForExportCanvas?.some(
      (t) =>
        t?.categoryId === categoryId && t.templateIds.includes(template.id),
    );
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" key={key}>
      <div
        className={`preview-card border mb-3 ${
          (source && selectedTemplate?.includes(source)) ||
          isActivePreviewCard()
            ? "active-preview-card"
            : ""
        }`}
        key={key}
      >
        {name && (
          <div className="d-flex justify-content-between py-1 px-2">
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {type === "video" ? (
                <div className="d-flex justify-content-between">
                  <span className="title-text">{name}</span>
                  {videoIconSvg}
                </div>
              ) : type === "audio" ? (
                <div className="d-flex justify-content-between">
                  <span className="title-text">{name}</span>
                  <div className="d-flex gap-2 align-items-center">
                    {buttonText && (
                      <ToolButton
                        type={ToolButtonEnum.BUTTON}
                        className="template-video-icon-btn"
                        title={t("cards.importOnCanvas")}
                        aria-label={t("cards.importOnCanvas")}
                        onClick={() =>
                          onAddtoCollectionBtnClick &&
                          onAddtoCollectionBtnClick()
                        }
                        size="small"
                      >
                        {importCanvas}
                      </ToolButton>
                    )}
                    {isDelete && (
                      <ToolButton
                        type={ToolButtonEnum.BUTTON}
                        className="audio-delete-icon"
                        title={t("cards.delete")}
                        aria-label={t("cards.delete")}
                        onClick={onDelete}
                        size="small"
                      >
                        {del}
                      </ToolButton>
                    )}
                  </div>
                </div>
              ) : (
                <span className="title-text">{name}</span>
              )}
              <span className="small-text">
                {Array.isArray(images) && images.length && `${likes} Likes`}
                {Array.isArray(images) &&
                  images.length > 0 &&
                  images.length > 1 &&
                  ` • ${images.length} Elements`}
              </span>
            </div>
          </div>
        )}
        {type === "video" ? (
          <video src={`${images}`} className="card-img" />
        ) : type === "audio" ? (
          <div className="d-flex justify-content-center align-items-center card-img">
            <audio
              controls
              controlsList="noplaybackrate nodownload"
              id={`audio${key}`}
              src={`${images}`}
              className="w-100"
            />
          </div>
        ) : Array.isArray(images) ? (
          images.length > 0 && (
            <CollectionImagePreview
              previewURL={images[0]}
              index={key}
              className={userName ? "card-img" : "card-img-top-image"}
            />
          )
        ) : (
          <CollectionImagePreview
            previewURL={images || ""}
            index={key}
            className={userName ? "card-img" : "card-img-top-image"}
          />
        )}
        <div className={`${userName ? "card-body" : ""}`}>
          {userName && (
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="d-flex">
                <Avatar onClick={() => {}} color={"#4c6ef5"} border={"#4c6ef5"}>
                  {userProfile && userProfile !== "null" ? (
                    <img
                      key={key}
                      src={userProfile}
                      alt=""
                      className="Avatar"
                    />
                  ) : (
                    userName?.substring(0, 2)
                  )}
                </Avatar>
                <span className="ms-2 mt-2 title-text">{userName}</span>
              </div>
              {created && (
                <span className="small-text">
                  {moment.utc(created).local().format("DD MMM YYYY, h:mm A")}
                </span>
              )}
            </div>
          )}
        </div>
        {type !== "audio" ? (
          <div
            className={`d-flex flex-column ${
              type !== "audio" && "card-overlay"
            } justify-content-between`}
            onMouseLeave={() => setOpenCategoryPopover(false)}
          >
            <div className="position-relative">
              {likeBookmarkIcon && (
                <div className="d-flex justify-content-between w-100 ">
                  {isMove && (
                    <button
                      style={{ height: 28, width: 28 }}
                      title="Add to category"
                      onClick={() => {
                        setOpenCategoryPopover(!openCategoryPopover);
                      }}
                    >
                      <PlusIcon color="#000" />
                    </button>
                  )}
                  <div>
                    {isLikefill ? (
                      <button
                        style={{ height: 28, width: 28 }}
                        onClick={async () => {
                          onLike && onLike();
                        }}
                      >
                        <span style={{ height: 25, width: 25 }}>
                          {fillLikeIcon}
                        </span>
                      </button>
                    ) : (
                      <button
                        style={{ height: 28, width: 28 }}
                        onClick={async () => {
                          onLike && onLike();
                        }}
                      >
                        {likeIcon}
                      </button>
                    )}
                    {isBookMarkFill ? (
                      <button
                        style={{ height: 30, width: 30 }}
                        onClick={() => {
                          onBookmark && onBookmark();
                        }}
                      >
                        {fillBookmarkIcon}
                      </button>
                    ) : (
                      <button
                        style={{ height: 30, width: 30 }}
                        onClick={() => {
                          onBookmark && onBookmark();
                        }}
                      >
                        {bookmarkIcon}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`d-flex justify-content-center align-items-center flex-wrap w-100 h-100 mb-3 ${
                  likeBookmarkIcon && "custom-category"
                }`}
              >
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  <p className="details__description d-none d-sm-none d-md-none d-lg-block">
                    {t("cards.connect")}
                  </p>
                  {buttonText && (
                    <button
                      type="button"
                      className="btn template-icon-btn"
                      onClick={() =>
                        onAddtoCollectionBtnClick && onAddtoCollectionBtnClick()
                      }
                    >
                      {buttonText}
                    </button>
                  )}
                  {isDelete && (
                    <div
                      className="btn template-delete-icon"
                      onClick={onDelete}
                    >
                      {del} {t("cards.delete")}
                    </div>
                  )}
                  {isEdit && (
                    <div
                      className="btn template-icon-btn px-4"
                      onClick={onEdit}
                    >
                      {edit} {t("cards.edit")}
                    </div>
                  )}
                </div>

                {isHoverPreviewBtns && (
                  <button
                    type="button"
                    className="btn  template-preview-btn"
                    onClick={() => {
                      setFullScreenCollectionPreview &&
                        setFullScreenCollectionPreview();
                      onPreview && onPreview();
                    }}
                  >
                    {preview} {t("cards.preview")}
                  </button>
                )}
                {isHoverUseThisTemplateBtn && (
                  <button
                    className="btn template-icon-btn"
                    onClick={() => {
                      if (isCustomCatagory) {
                        if (!template) return;
                        if (setSelectedCatagoryForExportCanvas && elements) {
                          setSelectedCatagoryForExportCanvas((prev: any) => {
                            let categoryIndex = -1;
                            prev.forEach((category: any, index: number) => {
                              if (category.categoryId === categoryId) {
                                categoryIndex = index;
                              }
                            });

                            if (categoryIndex !== -1) {
                              return prev.map(
                                (category: any, index: number) => {
                                  if (index === categoryIndex) {
                                    const templateIdIndex = category.templateIds.indexOf(
                                      template.id,
                                    );

                                    if (templateIdIndex !== -1) {
                                      const newTemplateIds = category.templateIds.filter(
                                        (id: string) => id !== template.id,
                                      );
                                      const newElements = category.elements.filter(
                                        (element: any) =>
                                          element.templateId !== template.id,
                                      );

                                      return {
                                        ...category,
                                        templateIds: newTemplateIds,
                                        elements: newElements,
                                      };
                                    } else {
                                      return {
                                        ...category,
                                        templateIds: [
                                          ...category.templateIds,
                                          template.id,
                                        ],
                                        elements: [
                                          ...category.elements,
                                          ...elements.map((element) => ({
                                            ...element,
                                            templateId: template.id,
                                            categoryId: categoryId,
                                          })),
                                        ],
                                      };
                                    }
                                  }
                                  return category;
                                },
                              );
                            } else {
                              const data = {
                                templateIds: [template?.id],
                                categoryId: categoryId,
                                elements: elements.map((element) => ({
                                  ...element,
                                  templateId: template.id,
                                  categoryId: categoryId,
                                })),
                              };
                              return [...prev, data];
                            }
                          });
                        }
                      } else {
                        return onUseThisTemplate?.();
                      }
                    }}
                  >
                    <span className="d-flex " style={{ height: 15, width: 15 }}>
                      {templateIcon}
                    </span>
                    {t("cards.useThisTemplate")}
                  </button>
                )}
              </div>
              {openCategoryPopover && (
                <MoveToCategoryPopover
                  setOpenCategoryPopover={setOpenCategoryPopover}
                  templateId={id ?? ""}
                  onAfterMovedToCategory={
                    onAfterMovedToCategory ?? ((category) => category)
                  }
                  templateCategory={templateCategory ?? []}
                  onAfterDeleteCategory={onAfterDeleteCategory ?? (() => {})}
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const CardWithImage = ({
  created,
  images,
  _key: key,
  name,
  userName,
  userProfile,
  type = "image",
  likes,
  isLikefill,
  isBookMarkFill,
  onLike,
  onBookmark,
  setFullScreenCollectionPreview,
  onPreview,
  buttonText = `${t("cards.addToCollection")}`,
  likeBookmarkIcon = true,
  isHoverPreviewBtns = true,
  isHoverUseThisTemplateBtn = true,
  onUseThisTemplate,
  source = "",
  onAddtoCollectionBtnClick,
  selectedTemplate,
  isDelete = false,
  onDelete,
  isEdit,
  onEdit,
  isMove,
  id,
  onAfterMovedToCategory,
  onAfterDeleteCategory,
  elements,
  templateCategory,
  isCustomCatagory,
  setSelectedCatagoryForExportCanvas,
  selectedCatagoryForExportCanvas,
  template,
  categoryId,
}: CollectionCardsProps) => {
  return (
    <CollectionCards
      created={created}
      images={images}
      _key={key}
      name={name}
      userName={userName}
      userProfile={userProfile}
      type={type}
      likes={likes}
      isLikefill={isLikefill}
      isBookMarkFill={isBookMarkFill}
      onLike={onLike}
      onBookmark={onBookmark}
      setFullScreenCollectionPreview={setFullScreenCollectionPreview}
      onPreview={onPreview}
      buttonText={buttonText}
      likeBookmarkIcon={likeBookmarkIcon}
      isHoverPreviewBtns={isHoverPreviewBtns}
      isHoverUseThisTemplateBtn={isHoverUseThisTemplateBtn}
      onUseThisTemplate={onUseThisTemplate}
      source={source}
      onAddtoCollectionBtnClick={onAddtoCollectionBtnClick}
      selectedTemplate={selectedTemplate}
      isDelete={isDelete}
      onDelete={onDelete}
      isEdit={isEdit}
      onEdit={onEdit}
      isMove={isMove}
      id={id}
      onAfterMovedToCategory={onAfterMovedToCategory}
      onAfterDeleteCategory={onAfterDeleteCategory}
      elements={elements}
      templateCategory={templateCategory}
      isCustomCatagory={isCustomCatagory}
      setSelectedCatagoryForExportCanvas={setSelectedCatagoryForExportCanvas}
      selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
      template={template}
      categoryId={categoryId}
    />
  );
};
