import _ from "lodash";
import OpenColor from "open-color";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../../../../../App/shareComponent/Loader";
import { MIME_TYPES } from "../../../../../../constants";
import { ExcalidrawElement, FileId } from "../../../../../../element/types";
import {
  fetchAllCollection,
  fetchFilteredCollectionData,
  userActionCollectionAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { t } from "../../../../../../i18n";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  LibraryFileType,
  LibraryItems,
} from "../../../../../../types";
import { LibraryType } from "../../../../../TemplateInfo";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { SidebarItemsEnum } from "../../../../components/constant/SidebarItems";
import { LibraryTabsEnum } from "../../../../LibraryItemsDialog";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";

export interface TemplateCollectionProps {
  searchVal: string;
  setFullScreenCollectionPreview: () => void;
  setPreviewTemplateData: (data: CollocationType) => void;
  loadElementsFromDB: (url: string) => void;
  selectedSortBy: number | null;
}

interface TemplateCollectionPropsTypes {
  setSelectedTemplate: (val: string[]) => void;
  selectedTemplate: string[];
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryItems) => void;
  setSelectedSection: (val: LibraryTabsEnum) => void;
}

export const TemplateCollection = forwardRef<
  FilterRef,
  TemplateCollectionProps & TemplateCollectionPropsTypes
>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      loadElementsFromDB,
      selectedTemplate,
      setSelectedTemplate,
      setAppState,
      appState,
      editCollection,
      setSelectedSection,
      selectedSortBy,
    },
    ref,
  ) => {
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [sort, setSort] = useState(1);
    const [search, setSearch] = useState("");
    const [isFilteredApplied, setIsFilteredAplied] = useState(false);
    const [selectedFilteredValues, setSelectedFilteredValues] = useState<
      Pick<FilteredValueType, "age" | "author" | "tags">
    >();

    const [filteredTemplates, setFilteredTemplates] = useState<
      CollocationType[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);

    useEffect(() => {
      setIsLoading(true);
      setAppState({ defaultLibraryTab: SidebarItemsEnum.WorkInProgress });
      const fetchCollection = async () => {
        try {
          const { result } = await fetchAllCollection({
            offset: page,
          });
          setTotalCount(result.totalCount);
          setFilteredTemplates(result?.collections);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };

      fetchCollection();
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchAllCollection({
            sort: index,
            offset: 0,
          });
          setPage(0);
          setSort(index);
          setTotalCount(result.totalCount);
          setFilteredTemplates([...result.collections]);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    const fetchFilteredData = async (
      filterDataWithKeys: Pick<FilteredValueType, "age" | "author" | "tags">,
    ) => {
      try {
        setIsLoading(true);
        setPage(0);

        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          search: search,
          offset: 0,
        });
        setTotalCount(result.totalCount);

        setFilteredTemplates([...result.collections]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error-in-fetchFilteredData", error);
      }
    };

    const removeFilters = async (hasFilterApplied: boolean) => {
      try {
        setIsLoading(true);
        if (hasFilterApplied) {
          const { result } = await fetchAllCollection({
            offset: page,
          });
          setFilteredTemplates(result.collections);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
      onSetIsFilteredAplied: setIsFilteredAplied,
      onSetSelectedFilteredValues: setSelectedFilteredValues,
    }));

    const debouncedSearch = useCallback(
      _.debounce(async (searchVal) => {
        setIsLoading(true);
        const { result } = await fetchAllCollection({
          search: searchVal,
          sort: sort,
          offset: 0,
        });
        setPage(0);
        setTotalCount(result.totalCount);
        setIsLoading(false);
        setFilteredTemplates([...result.collections]);
      }, 500),
      [],
    );

    useEffect(() => {
      debouncedSearch(searchVal);
      setSearch(searchVal);
    }, [searchVal, debouncedSearch]);

    const onAddtoCollectionBtnClick = (collectionSource: string) => {
      if (selectedTemplate.includes(collectionSource)) {
        // If the source is already selected, remove it from the array
        setSelectedTemplate(
          selectedTemplate.filter(
            (source: string) => source !== collectionSource,
          ),
        );
      } else {
        setSelectedTemplate([...selectedTemplate, collectionSource]);
      }
    };

    const onLikeCollection = async (templateId: string) => {
      const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
      const res = await userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "LIKE",
      });
      const collectionActivity = res.result;
      const templateIndex = filteredTemplates.findIndex(
        (template) => template.id === templateId,
      );

      const data = [...filteredTemplates];

      data[templateIndex]["CollectionActivities"] = [collectionActivity];

      if (data[templateIndex]["CollectionActivities"]?.[0]?.isLiked) {
        data[templateIndex].likeCount += 1;
      } else {
        data[templateIndex].likeCount -= 1;
      }
      setFilteredTemplates([...data]);
    };
    const onBookmarkCollection = async (templateId: string) => {
      const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
      const res = await userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "BOOKMARK",
      });
      const collectionActivity = res.result;
      const templateIndex = filteredTemplates.findIndex(
        (template) => template.id === templateId,
      );

      const data = [...filteredTemplates];

      data[templateIndex]["CollectionActivities"] = [collectionActivity];

      setFilteredTemplates([...data]);
    };

    const onEdit = async (item: CollocationType) => {
      const response = await fetch(item.source);
      const result = await response.json();

      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryId: appState.editingLibrary.libraryInfo.id,
          isEditing: true,
          libraryInfo: item,
          libraryItems: result?.libraryItems[0]?.elements,
          oldElements: [],
        },
      });
      const modifiedLibraryItems = result?.libraryItems?.length
        ? result?.libraryItems.map((libraryItem: LibraryType) => {
            return { ...libraryItem, isSelected: false };
          })
        : [];

      editCollection(modifiedLibraryItems);
      generateSvgForLibrary(modifiedLibraryItems, item);
      setSelectedSection(LibraryTabsEnum.MyLibrary);
    };

    const getFilesByLibraryElements = (
      elements: ExcalidrawElement[],
      templateInfo?: CollocationType,
    ) => {
      const files: BinaryFiles = {};

      const hasRequiredType = (element: ExcalidrawElement) =>
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "mermaidDiagram" ||
        element.type === "video" ||
        element.type === "audio" ||
        element.type === "textWithStyles";

      for (const element of elements) {
        if (hasRequiredType(element) && element.fileId !== null) {
          const fileFromAppState = appState.files?.[element.fileId];
          if (fileFromAppState) {
            files[element.fileId] = fileFromAppState;
          } else if (templateInfo?.files) {
            const matchingFileIndex = templateInfo.files.findIndex(
              (file: LibraryFileType) => file.id === element.fileId,
            );
            if (matchingFileIndex !== -1) {
              const elementFile = templateInfo.files[
                matchingFileIndex
              ] as LibraryFileType;
              files[elementFile.id] = {
                mimeType: elementFile.mimeType || MIME_TYPES.binary,
                id: elementFile.id,
                dataURL: elementFile.url as any,
                created: elementFile.created,
                isPublished: true,
              };
            }
          }
        }
      }

      return files;
    };

    const generateSvgForLibrary = async (
      libraryItems: LibraryType[],
      item: CollocationType,
    ) => {
      let files_: BinaryFiles = {};
      const librariesWithSvg: LibraryType[] = await Promise.all(
        libraryItems.map(async (libraryItem: LibraryType) => {
          const files = getFilesByLibraryElements(libraryItem.elements, item);
          files_ = {
            ...files_,
            ...files,
          };
          const svg = libraryItem?.elements?.length
            ? await exportToSvg(
                libraryItem.elements,
                {
                  exportBackground: false,
                  viewBackgroundColor: OpenColor.white,
                },
                files,
              )
            : null;
          return {
            ...libraryItem,
            svg,
          };
        }),
      );

      setAppState({
        files: {
          ...appState.files,
          ...files_,
        },
      });
    };

    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    const fetchMoreCollections = async () => {
      const pageCount = Number(page) + 1;
      if (isFilteredApplied) {
        const { result } = await fetchFilteredCollectionData({
          ...(selectedFilteredValues as any),
          search,
          offset: pageCount,
        });
        setTotalCount(result.totalCount);
        setFilteredTemplates([...filteredTemplates, ...result.collections]);
      } else {
        const { result } = await fetchAllCollection({
          search,
          sort: sort,
          offset: pageCount,
        });
        setTotalCount(result.totalCount);
        setFilteredTemplates([...filteredTemplates, ...result.collections]);
      }
      setPage(pageCount);
    };

    return (
      <div
        className="row overflow-auto templatecollection"
        style={{ height: "calc(100% - 28%)" }}
      >
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          <InfiniteScroll
            hasMore={totalCount > filteredTemplates.length}
            initialLoad={false}
            loadMore={fetchMoreCollections}
            useWindow={false}
            getScrollParent={() =>
              document.querySelector(".templatecollection")
            }
            className="row"
            loader="Loading..."
          >
            {filteredTemplates.map((template, index) => {
              const isUserTemplate = template.userEmail === userMail;
              return (
                <CardWithImage
                  key={index}
                  images={template.preview}
                  userProfile={template.author?.url}
                  userName={template.author?.name}
                  created={template.createdAt}
                  name={template.name}
                  likes={template.likeCount}
                  isLikefill={template.CollectionActivities?.[0]?.isLiked}
                  onLike={async () => await onLikeCollection(template.id)}
                  isBookMarkFill={
                    template.CollectionActivities?.[0]?.isBookmarked
                  }
                  onBookmark={async () => {
                    await onBookmarkCollection(template.id);
                  }}
                  setFullScreenCollectionPreview={
                    setFullScreenCollectionPreview
                  }
                  onPreview={() => {
                    setPreviewTemplateData(template);
                  }}
                  onUseThisTemplate={() => loadElementsFromDB(template.source)}
                  source={template.source}
                  onAddtoCollectionBtnClick={() =>
                    onAddtoCollectionBtnClick(template.source)
                  }
                  selectedTemplate={selectedTemplate}
                  isEdit={isUserTemplate}
                  onEdit={() => {
                    isUserTemplate && onEdit(template);
                    localStorage.removeItem("selectedElement");
                  }}
                  buttonText={t("cards.addToMyLibrary")}
                  isMove={true}
                  id={template.id}
                  onAfterMovedToCategory={(category: string[]) => {
                    if (category.length) {
                      const addCategory = category?.filter(
                        (category: string) => category !== template.id,
                      );
                      const index = filteredTemplates.findIndex(
                        (data) => data.id === template.id,
                      );
                      if (addCategory) {
                        const data = [...filteredTemplates];

                        data[index].category = [...addCategory];

                        setFilteredTemplates([...data]);
                      }
                    }
                    setToastMessage({
                      message: "Collection successfully imported.",
                      type: ToastType.SUCCESS,
                    });
                  }}
                  onAfterDeleteCategory={(id) => {
                    if (template.id !== id) {
                      const updatedCategory = template.category?.filter(
                        (category) => category !== id,
                      );
                      const index = filteredTemplates.findIndex(
                        (data) => data.id === template.id,
                      );
                      if (updatedCategory) {
                        const data = [...filteredTemplates];

                        data[index].category = [...updatedCategory];

                        setFilteredTemplates([...data]);
                      }
                    }
                  }}
                  templateCategory={template.category}
                  isCustomCatagory={false}
                />
              );
            })}
          </InfiniteScroll>
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
